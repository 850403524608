import { Card } from "@components/cards/card";
import { InputSearch } from "@components/forms/input-search";
import { Pagination } from "@components/pagination";
import { LoadingSkeleton } from "@components/skeleton-loading";
import { IDepositPaymentDTO } from "@hooks/dtos/IDepositDto";
import { Masks } from "@hooks/functions/Masks/Masks";
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";
import { api } from "@service/api";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const status = {
    processed: {
        color: "success",
        text: "Pago",
    },
    waiting: {
        color: "warning",
        text: "Pendente",
    },
    failed: {
        color: "danger",
        text: "Deletado",
    }
};


const masks = new Masks();
export function AproveDeposits() {
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [deposits, setDeposits] = useState<IDepositPaymentDTO[]>([]);
    const [search, setSearch] = useState("");
    const { actions: { pushToastify } } = ZuToastifyStore()

    async function historicDeposits(page: number, search: string = "") {
        try {
            const response = await api.post(`/manager/tokens/transactions/pagination`, {
                search: search.trim() || undefined,
                status: 'waiting',
                op: 1,
                page: page
            });
            setDeposits(response.data?.result || []);
            setTotalPages(response.data?.page || 1);
        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro!",
                type: 'error',
                theme: 'dark',
            });
        }
        setIsLoading(false);
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setSearch(e.target.value);
    };

    useEffect(() => {
        historicDeposits(page);
    }, [page]);

    useEffect(() => {
        setIsLoading(true);
        const delayDebounceFn = setTimeout(() => {
            setPage(1)
            historicDeposits(1, search);
        }, 2500);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    return (
        <div className="row">
            <div className="col-md-12">
                <Card className="card-round">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-10">
                                <h4 className="card-title">Aprovar Depósitos</h4>
                                <p className="card-category">Lista de todos os pedidos de depósito para aprovar</p>
                            </div>
                            <div className="col-md-2">
                                <InputSearch animated label="Buscar" placeholder="" value={search} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <div id="basic-datatables_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                                <div id="basic-datatables_length" className="dataTables_length">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr role="row">
                                                <th scope="col" className="fs-6">#</th>
                                                <th scope="col" className="fs-6">Usuário</th>
                                                <th scope="col" className="fs-6">De</th>
                                                <th scope="col" className="fs-6">Para</th>
                                                <th scope="col" className="fs-6">Currency</th>
                                                <th scope="col" className="fs-6">Valor</th>
                                                <th scope="col" className="fs-6">Taxa</th>
                                                <th scope="col" className="fs-6">Status</th>
                                                <th scope="col" className="fs-6">Data</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                                <tr>
                                                    <td colSpan={9}>
                                                        <LoadingSkeleton />
                                                    </td>
                                                </tr>
                                            ) : deposits.length > 0 ? (
                                                deposits.map((deposit) => (
                                                    <tr key={deposit.id}>
                                                        <td data-title="#">{deposit.id}</td>
                                                        <td data-title="Usuário">{typeof deposit.customer?.id ? deposit.customer?.id : 'N/A'}</td>
                                                        <td data-title="From">{deposit?.address_from}</td>
                                                        <td data-title="To">{deposit?.address_to}</td>
                                                        <td data-title="Currency">{deposit?.currency}</td>
                                                        <td data-title="Value">{masks.dollarCurrency(deposit.value)}</td>
                                                        <td data-title="Taxa">{masks.dollarCurrency(deposit.value_fee)}</td>
                                                        <td data-title="Status">
                                                            {status[deposit.status] ? (
                                                                <span className={`fs-6 badge badge-${status[deposit.status].color}`}>
                                                                    {status[deposit.status].text}
                                                                </span>
                                                            ) : (
                                                                <span className="fs-6 badge badge-secondary">Status Desconhecido</span>
                                                            )}
                                                        </td>
                                                        <td data-title="Pagamento">
                                                            {deposit.updatedAt ? dayjs(deposit.updatedAt).format('DD/MM/YYYY HH:mm:ss') : '~'}
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={9} className="text-center">Nenhum depósito encontrado</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Pagination page={page} setPage={setPage} totalPages={totalPages} maxPageShow={3} />
                    </div>
                </Card>
            </div>
        </div>
    );
}
