import { Button } from '@components/buttons/button';
import { Close } from '@components/buttons/close';
import { ImageBannerUploader } from '@components/forms/img-banner';
import { Input } from '@components/forms/input';
import { yupResolver } from '@hookform/resolvers/yup';
import { IBannerAddDTO } from '@hooks/dtos/forms/IBannerAddDTO';
import { IBannerConfigDTO, IBannerDTO } from '@hooks/dtos/IBannerDTO';
import { ZuToastifyStore } from '@hooks/stores/zustand-toastify-store';
import { api } from "@service/api";
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    bannerConfig: IBannerConfigDTO;
    banners: IBannerDTO[];
}

export function ModalAddBanner({ isOpen, onClose, bannerConfig, banners }: IProps) {
    const [animate, setAnimate] = useState(false);
    const [nextAvailableOrder, setNextAvailableOrder] = useState<number>(1);
    const [base64Image, setBase64Image] = useState<string>(``);
    const { actions: { pushToastify } } = ZuToastifyStore();

    const scheme = yup.object().shape({
        name: yup.string().required('Declare um nome ou apelido para facilitar a identificação.'),
        link: yup.string().url('O link precisa ser uma URL válida.'),
        order: yup.number().required('A ordem é obrigatória.'),
        status: yup.string().required('Selecione um status válido.'),
    });

    const { register, reset, handleSubmit, formState: { errors } } = useForm<IBannerAddDTO>({
        resolver: yupResolver(scheme) as any,
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
    });

    async function handleCreate(data: IBannerAddDTO) {
        try {
            await api.post('/manager/customization/banner/create', {
                name: data?.name,
                link: data?.link,
                status: data?.status,
                order: data?.order,
                file: base64Image
            });

            reset({ name: ``, link: ``, order: nextAvailableOrder, file: ``, status: `a` });

            pushToastify({
                message: `Banner inserido com sucesso.`,
                type: 'success',
                theme: "dark",
            });

            setTimeout(() => onClose(), 300);
        } catch (error: any) {
            pushToastify({
                message: error?.response?.data?.message,
                type: 'error',
                theme: "dark",
            });
        }
    }

    async function checkOrder(order: number) {
        const exists = banners.some(_banner => _banner.order === order && _banner.status === "a");
        if (exists) {
            pushToastify({
                message: "Esta ação trocará de posição com banner da posição selecionada!",
                type: 'info',
                theme: "dark",
                pauseOnHover: true
            });
        } else {
            pushToastify({
                message: "Posição válida, não há banner ocupando esta ordem.",
                type: 'success',
                theme: "dark",
                pauseOnHover: true
            });
        }
    }

    function calculateNextAvailableOrder(max: number) {
        let order = 1;
        for (let i = 1; i <= max; i++) {
            if (!banners.some(banner => banner.order === i && banner.status === 'a')) {
                order = i;
                break;
            }
        }
        setNextAvailableOrder(order);
        reset({ name: ``, link: ``, order: order, status: `a` });
    }

    function handleClose() {
        setAnimate(false);
        setTimeout(() => onClose(), 300);
    };

    function handleEscape(evt: KeyboardEvent) {
        if (evt.key === 'Escape' || evt.key === 'Esc' || evt.keyCode === 27) {
            handleClose();
        }
    };

    useEffect(() => {
        if (isOpen) {
            
            setBase64Image(``);
            
            // --- Reset the Inputs Values --- //
            reset({ name: ``, link: ``, order: nextAvailableOrder, file: ``, status: `a` });

            // --- Calculate the Next Available Order --- //
            calculateNextAvailableOrder(bannerConfig?.home?.max || 1);

            setTimeout(() => setAnimate(true), 10);
            document.addEventListener('keydown', handleEscape);

            return () => {
                document.removeEventListener('keydown', handleEscape);
            };
        } else {
            setAnimate(false);
        }
    }, [isOpen]);

    return (
        <>
            <div className={`modal fade ${animate && isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }} aria-labelledby="staticBackdropLabel" aria-hidden={!isOpen}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="contentModallLabel">Adição de Banner</h5>
                            <Close onPress={handleClose} />
                        </div>
                        <div className="modal-body">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit(handleCreate)();
                            }}>
                                <div className="row gap-3">
                                    {/* Name */}
                                    <div className="col-md-12">
                                        <Input
                                            animated
                                            label="Nome"
                                            placeholder=""
                                            type='text'
                                            register={register('name')}
                                            errorMessage={String(errors?.name?.message || "")}
                                        />
                                    </div>
                                    {/* URL | Link */}
                                    <div className="col-md-12">
                                        <Input
                                            animated
                                            label="URL"
                                            placeholder=""
                                            type='text'
                                            register={register('link')}
                                            errorMessage={String(errors?.link?.message || "")}
                                        />
                                    </div>
                                    {/* Ordination */}
                                    <div className="col-md-12">
                                        <div className="form-floating">
                                            <select
                                                className={`form-select`}
                                                {...register('order')}
                                                defaultValue={nextAvailableOrder}
                                                onChange={(e) => checkOrder(Number(e.target.value))}
                                            >
                                                {bannerConfig?.home?.max ? (
                                                    Array.from({ length: bannerConfig.home.max }, (_, index) => (
                                                        <option key={index} value={index + 1}>
                                                            {`${index + 1}º`}
                                                        </option>
                                                    ))
                                                ) : (
                                                    <option value="">Carregando...</option>
                                                )}
                                            </select>
                                            <label>Ordenação</label>
                                        </div>
                                    </div>
                                    {/* Status */}
                                    <div className="col-md-12 mt-3">
                                        <div className="form-floating">
                                            <select
                                                className={`form-select`}
                                                {...register('status')}
                                                defaultValue={'a'}
                                            >
                                                {["a", "i"].map(index => (
                                                    <option key={index} value={index}>
                                                        {`${index === "a" ? `Ativo` : `Inativo`}`}
                                                    </option>
                                                ))}
                                            </select>
                                            <label>Status</label>
                                        </div>
                                    </div>
                                    {/* Banner */}
                                    <div className="col-md-12">
                                        <ImageBannerUploader
                                            width={bannerConfig?.home?.dimension?.width || 1360}
                                            height={bannerConfig?.home?.dimension?.height || 241}
                                            label="Banner"
                                            register={register('file')}
                                            errorMessage={String(errors?.file?.message || "A imagem não atende aos requisitos.")}
                                            onBase64Change={setBase64Image}
                                            base64Value={base64Image}
                                        />
                                    </div>

                                    <div className="col-md-12 mb-2 col-12 text-end">
                                        <Button type="submit" children="Salvar" className="btn btn-success py-2 px-5" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal-backdrop fade ${animate && isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }}></div>
        </>
    )
}
