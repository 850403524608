import Default from "@assets/img/defaultAvatar.png";
import { Card } from "@components/cards/card";
import { CardInfo } from "@components/cards/card-info";
import { LoadingSkeleton } from "@components/skeleton-loading";
import { IBiggestBalances, IDates, IsLoading, IWithdrawData } from "@hooks/dtos/IHome";
import { Masks } from "@hooks/functions/Masks/Masks";
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";
import { api } from "@service/api";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

interface IMoneyData {
  type: string;
  total: string;
}


const masks = new Masks();
export function HomeCoin() {
  const [bonifications, setBonifications] = useState<{ [key: string]: number }>({
    now: 0,
    week: 0,
    monthly: 0,
    year: 0,
});
  const [withdrawsData, setWithdrawsData] = useState<IWithdrawData[]>([]);
  const [usersBiggestBalance, setUsersBiggestBalance] = useState<IBiggestBalances[]>([]);
  const [isWithdrawsLoading, setIsWithdrawsLoading] = useState(false);
  const [isBiggestBalancesLoading, setIsBiggestBalancesLoading] = useState(true);
  const { actions: { pushToastify } } = ZuToastifyStore()

  const [isLoading, setIsLoading] = useState<IsLoading>({
        now: true,
        week: true,
        monthly: true,
        year: true,
    });

    const week = new Date();
    const today = new Date();
    const dates = {
        now: dayjs(today).format('YYYY-MM-DD'),
        week: dayjs(new Date(week.setDate(week.getDate() - week.getDay()))).format('YYYY-MM-DD'),
        month: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format('YYYY-MM-DD'),
        year: dayjs(new Date(today.getFullYear(), 0, 1)).format('YYYY-MM-DD')
    }

  async function getTotalBonifications() {
        try {
            const keys: (keyof IDates)[] = ["now", "week", "month", "year"];
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                const [response] = await Promise.all([
                    api.post("/manager/metrics/total/bonifications/coin", {
                        coinsTypes: 1,
                        startDate: dates[key]
                    }),
                ])

                setBonifications((prev) => ({
                ...prev,
                [key]: response.data.total,
            }));

                setIsLoading((prev) => ({ ...prev, [key]: false }));
            }
        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro!",
                type: 'error',
                theme: 'dark',
            });
            setIsLoading((prev) => ({ ...prev, now: false, week: false, monthly: false, year: false }));

        }
    }

  async function getBiggestBalances() {
    try {
      const result = await api.post("/manager/metrics/biggest/balance/coin", {
        coinsTypes: 1
      });
      setUsersBiggestBalance(result.data);
    } catch (err: any) {
      pushToastify({
        message: err?.response?.data.message ?? "Ocorreu um erro!",
        type: 'error',
        theme: 'dark',
      });
    }
    setIsBiggestBalancesLoading(false);
  }

  useEffect(() => {
    getTotalBonifications();
    getBiggestBalances();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-4">
            <Card>
                <CardInfo
                    isLoading={isLoading.now}
                    title="Total de Bonificações"
                    coin="coin"
                    value={bonifications.now}
                />
                <span className="position-absolute end-0 bg-info p-2 rounded-end rounded-bottom-0 text-white">
                    Diário
                </span>
            </Card>
        </div> 
        <div className="col-md-4">
            <Card>
                <CardInfo
                    isLoading={isLoading.week}
                    title="Total de Bonificações"
                    coin="coin"
                    value={bonifications.week}
                />
                <span className="position-absolute end-0 bg-info p-2 rounded-end rounded-bottom-0 text-white">
                    Semanal
                </span>
            </Card>
        </div>   
        <div className="col-md-4">
            <Card>
                <CardInfo
                    isLoading={isLoading.week}
                    title="Total de Bonificações"
                    coin="coin"
                    value={bonifications.month}
                />
                <span className="position-absolute end-0 bg-info p-2 rounded-end rounded-bottom-0 text-white">
                    Mensal
                </span>
            </Card>
        </div>          
      </div>

      
    <div className="row">
      <div className="col-md-6">
          <Card className="scroll h-100">
            <div className="card-header">
              <div className="card-title">
                <div className="row align-items-center">
                  <div className="col-md-9">
                    <span>Maiores Saldos</span>                  
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body text-center">
              {isBiggestBalancesLoading ? (
                <div>
                  <LoadingSkeleton count={6} />
                </div>
              ) : usersBiggestBalance.length > 0 ? (
                usersBiggestBalance.map((user) => (
                  <div key={user.customer} className="row mb-3 border-bottom border-2 pb-2 align-items-center gap-md-0 gap-2">
                    <div className="col-md-4 text-center">
                      <div className="avatar avatar-lg">
                        <img src={user?.photos ?? Default} alt="" className="avatar-img rounded-circle" />
                      </div>
                    </div>
                    <div className="col-md-4 d-flex flex-column text-md-start text-center gap-2">
                      <span className="text-truncate">{user.userName}</span>
                      <span className="text-truncate">{user.email}</span>
                    </div>
                    <div className="col-md-4 d-flex flex-column text-center gap-2">
                      <span className="fw-bold">Saldo</span>
                      <span>{masks.cryptoCurrency(user.balance)} <small className="text-muted">PNDC</small></span>
                    </div>
                  </div>
                ))
              ) : (
                <span>Nenhum registro encontrado.</span>
              )}
            </div>
          </Card>
        </div>

        <div className="col-md-6">
          <Card className="scroll h-100">
            <div className="card-header">
              <div className="card-title">
                <span>Maiores Apostas</span>
              </div>
            </div>
            <div className="card-body text-center">
              {isWithdrawsLoading ? (
                <div>
                  <LoadingSkeleton count={7} />
                </div>
              ) : withdrawsData?.length > 0 ? (
                withdrawsData.map((withdraw) => (
                  <div key={withdraw.id} className="row border-bottom border-2 pb-2 align-items-center gap-md-0 gap-2">
                    <div className="col-md-2 text-center text-md-start">
                      <div className="avatar avatar-lg">
                        <img src={withdraw?.avatar ?? Default} alt="" className="avatar-img rounded-circle" />
                      </div>
                    </div>
                    <div className="col-md-4 d-flex flex-column text-md-start text-center gap-2">
                      <span className="text-truncate">{withdraw.customer}</span>
                      <span className="text-truncate">{withdraw.email}</span>
                      <span>{masks.cpf(withdraw.document)}</span>
                    </div>
                    <div className="col-md-3 d-flex flex-column text-md-start text-center gap-2">
                      <span>{masks.coinCurrency(withdraw.value)}</span>
                      {/* <span className="badge badge-info ms-md-0 me-auto">{withdraw.method}</span> */}
                    </div>
                    <div className="col-md-3 d-flex flex-column text-md-start text-center gap-2">
                      <span className="badge badge-success ms-md-0 me-auto">Aprovado</span>
                      <span>{dayjs(withdraw.dateClose).format("DD/MM/YYYY [ás] HH:mm:ss")}</span>
                    </div>
                  </div>
                ))
              ) : (
                <span>Nenhum registro encontrado.</span>
              )}
            </div>
          </Card>
        </div>
    </div>
    </>
  );
}