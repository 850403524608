import { Card } from "@components/cards/card";
import { Pagination } from "@components/pagination";
import { LoadingSkeleton } from "@components/skeleton-loading";
import { IBetsHistorical } from "@hooks/dtos/customer/ICustomerDTO";
import { Masks } from "@hooks/functions/Masks/Masks";
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";

import { api } from "@service/api";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

interface HistoricalProps {
  id: number;
}

const statusBets = {
  p: {
    color: "info",
    text: "Pendente",
  },
  w: {
    color: "success",
    text: "Ganho"
  },
  hw: {
    color: "success",
    text: "Ganho parcial"
  },
  l: {
    color: "warning",
    text: "Perca"
  },
  hl: {
    color: "warning",
    text: "Perca parcial"
  },
  x: {
    color: "danger",
    text: "Cancelado",
  },
}
const masks = new Masks();
export function HistoricalCoin({ id }: HistoricalProps) {
  type Category = keyof typeof descriptions;
  const descriptions: Record<'bets', string> = {

    bets: `Lista de apostas do usuário ${id}.`,
  };

  const [category, setCategory] = useState<Category>('bets');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [bets, setBets] = useState<IBetsHistorical>();

  const [description, setDescription] = useState<string>(descriptions[category]);
  const [isLoading, setIsLoading] = useState(true);

  const { actions: { pushToastify } } = ZuToastifyStore()

  async function getHistorical(page: number) {
    try {
      let data;
      if (category === 'bets') data = bets;

      if (page > 1 || data === undefined || data?.currentPage != 1) {
        const result = await api.post("/manager/customer/historical/coin", { customerId: id, page, category });

        result.data.currentPage = page;
        if (category === 'bets') setBets(result.data);

        setTotalPages(result.data.pages);

      } else {
        if (category === 'bets') setTotalPages(data.pages);
      }
    } catch (err: any) {
      pushToastify({
        message: err?.response?.data.message ?? "Ocorreu um erro!",
        type: 'error',
        theme: 'dark',
      });
    }

    setIsLoading(false);
  }


  function getCategory(category: Category) {
    setDescription(descriptions[category as Category]);
    setCategory(category);
  }

  useEffect(() => {
    setIsLoading(true);
    getHistorical(page);
  }, [page])

  useEffect(() => {
    setIsLoading(true);
    getHistorical(1);
  }, [category])

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Card>
            <div className="card-header">
              <div className="row">
                <div className="col-md-10">
                  <ul className="nav nav-pills nav-secondary" id="pills-tab" role="tablist">
                    <li className="nav-item submenu" role="presentation">
                      <a className={`nav-link ${category === "bets" ? "active" : ""}`} id="pills-bets-tab" onClick={() => getCategory("bets")} data-bs-toggle="pill" href="#pills-bets" role="tab" aria-controls="pills-bets" aria-selected={category === "bets"}>Apostas</a>
                    </li>
                  </ul>
                  <div className="card-category">{description}</div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      {category === "bets" &&
                        <>
                          <th>Game</th>
                          <th>Round</th>
                          <th>Aposta</th>
                          <th>Saldo</th>
                          <th>Profit</th>
                          <th>Taxa da casa</th>
                          <th>Taxa do Provedor</th>
                          <th>Status</th>
                          <th>Data da aposta</th>
                        </>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan={10} className="text-center">
                          <LoadingSkeleton />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {category === "bets" && (
                          bets?.result && bets.result.length > 0 ? (bets.result.map((bet) => (
                            <tr key={bet.id}>
                              <th scope="row" className="text-start">{bet.id}</th>
                              <td className="text-start">{bet?.gameData?.name}</td>
                              <td className="text-start">{bet.round}</td>
                              <td className="text-start">{masks.coinCurrency(bet.amount)}</td>
                              <td className="text-start">{masks.coinCurrency(bet.balance)}</td>
                              <td className="text-start">{masks.coinCurrency(bet.profit)}</td>
                              <td className="text-start">{masks.coinCurrency(bet.houseFee)}</td>
                              <td className="text-start">{masks.coinCurrency(bet.providerFee)}</td>
                              <td className="text-start">
                                <span className={`fs-6 badge badge-${statusBets[bet.status].color}`}>
                                  {statusBets[bet.status].text}
                                </span>
                              </td>
                              <td className="text-start">{dayjs(bet.createdAt).format('DD/MM/YYYY [ás] HH:mm:ss')}</td>
                            </tr>
                          ))
                          ) : (
                            <tr>
                              <td colSpan={10} className="text-center">Nenhum registro encontrado.</td>
                            </tr>
                          )
                        )}
                      </>
                    )}
                  </tbody>

                </table>
                <Pagination setPage={setPage} page={page} totalPages={totalPages} maxPageShow={2} />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  )
}
