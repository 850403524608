import { Card } from "@components/cards/card";
import { Pagination } from "@components/pagination";
import { LoadingSkeleton } from "@components/skeleton-loading";
import { IDepositHistorical, ISwapTokens, IWithdrawsHistorical } from "@hooks/dtos/customer/ICustomerDTO";
import { Masks } from "@hooks/functions/Masks/Masks";
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";

import { api } from "@service/api";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

interface HistoricalProps {
  id: number;
}

const statusDeposit = {
  waiting: {
    color: "warning",
    text: "Pendente",
  },
  s: {
    color: "success",
    text: "Pago"
  },
  processed: {
    color: "success",
    text: "Aprovado",
  },
  h: {
    color: "danger",
    text: "Deletado",
  },
  failed: {
    color: "danger",
    text: "Cancelado",
  },
}

const statusWithdraw = {
  s: {
    color: "success",
    text: "Enviado"
  },
  processed: {
    color: "success",
    text: "Aprovado",
  },
  waiting: {
    color: "warning",
    text: "Pendente",
  },
  failed: {
    color: "danger",
    text: "Cancelado",
  },
}

const statusswap = {
  p: {
    color: "info",
    text: "Pendente",
  },
  w: {
    color: "success",
    text: "Ganho"
  },
  hw: {
    color: "success",
    text: "Ganho parcial"
  },
  l: {
    color: "warning",
    text: "Perca"
  },
  hl: {
    color: "warning",
    text: "Perca parcial"
  },
  x: {
    color: "danger",
    text: "Cancelado",
  },
}
const masks = new Masks();
export function HistoricalUsdt({ id }: HistoricalProps) {
  type Category = keyof typeof descriptions;
  const descriptions: Record<'deposits' | 'withdraws' | 'swaps', string> = {
    deposits: `Lista de depósitos do usuário ${id}.`,
    withdraws: `Lista de saques do usuário ${id}.`,
    swaps: `Lista de swap do usuário ${id}.`,
  };

  const [category, setCategory] = useState<Category>('deposits');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [deposits, setDeposits] = useState<IDepositHistorical>();
  const [withdraws, setWithdraws] = useState<IWithdrawsHistorical>();
  const [swap, setSwap] = useState<ISwapTokens>();

  const [description, setDescription] = useState<string>(descriptions[category]);
  const [isLoading, setIsLoading] = useState(true);

  const { actions: { pushToastify } } = ZuToastifyStore()

  async function getHistorical(page: number) {
    try {
      let data;
      if (category === 'deposits') data = deposits;
      if (category === 'withdraws') data = withdraws;
      if (category === 'swaps') data = swap;

      if (page > 1 || data === undefined || data?.currentPage != 1) {
        const result = await api.post("/manager/customer/historical/tokens", { customerId: id, page, category });
        result.data.currentPage = page;
        if (category === 'deposits') setDeposits(result.data);
        if (category === 'withdraws') setWithdraws(result.data);
        if (category === 'swaps') setSwap(result.data);

        setTotalPages(result.data.pages);

      } else {
        if (category === 'deposits') setTotalPages(data.pages);
        if (category === 'withdraws') setTotalPages(data.pages);
        if (category === 'swaps') setTotalPages(data.pages);
      }
    } catch (err: any) {
      pushToastify({
        message: err?.response?.data.message ?? "Ocorreu um erro!",
        type: 'error',
        theme: 'dark',
      });
    }

    setIsLoading(false);
  }

  function getCategory(category: Category) {
    setDescription(descriptions[category as Category]);
    setCategory(category);
  }

  useEffect(() => {
    setIsLoading(true);
    getHistorical(page);
  }, [page])

  useEffect(() => {
    setIsLoading(true);
    getHistorical(1);
  }, [category])

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Card>
            <div className="card-header">
              <div className="row">
                <div className="col-md-10">
                  <ul className="nav nav-pills nav-secondary" id="pills-tab" role="tablist">
                    <li className="nav-item submenu " role="presentation">
                      <a className={`nav-link ${category === "deposits" ? "active" : ""}`} id="pills-deposits-tab" onClick={() => getCategory("deposits")} data-bs-toggle="pill" href="#pills-deposits" role="tab" aria-controls="pills-deposits" aria-selected={category === "deposits"}>Depósitos</a>
                    </li>
                    <li className="nav-item submenu" role="presentation">
                      <a className={`nav-link ${category === "withdraws" ? "active" : ""}`} id="pills-withdraws-tab" onClick={() => getCategory("withdraws")} data-bs-toggle="pill" href="#pills-withdraws" role="tab" aria-controls="pills-withdraws" aria-selected={category === "withdraws"}>Retiradas</a>
                    </li>
                    <li className="nav-item submenu" role="presentation">
                      <a className={`nav-link ${category === "swaps" ? "active" : ""}`} id="pills-swap-tab" onClick={() => getCategory("swaps")} data-bs-toggle="pill" href="#pills-swap" role="tab" aria-controls="pills-swap" aria-selected={category === "swaps"}>Swaps</a>
                    </li>
                  </ul>
                  <div className="card-category">{description}</div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      {category === "deposits" &&
                        <>
                          {/* <th>Tr Id</th> */}
                          {/* <th>Método</th> */}
                          <th>Status</th>
                          <th>Valor</th>
                          <th>Taxa</th>
                          {/* <th>Pago</th> */}
                          <th>Data de pagamento</th>
                        </>
                      }
                      {category === "withdraws" &&
                        <>
                          <th>De</th>
                          <th>Para</th>
                          <th>Hash</th>
                          <th>Status</th>
                          <th>Valor</th>
                          <th>Taxa</th>
                          <th>Data de criação</th>
                        </>
                      }
                      {category === "swaps" &&
                        <>
                          <th>Enviou</th>
                          <th>Recebeu</th>
                          <th>Quantidade</th>
                          <th>Taxa</th>
                          <th>Data</th>
                        </>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan={10} className="text-center">
                          <LoadingSkeleton />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {category === "deposits" && (
                          deposits?.result && deposits.result.length > 0 ? (deposits.result.map((deposit) => (
                            <tr key={deposit.id}>
                              <th scope="row" className="text-start">{deposit.id}</th>
                              {/* <td className="text-start">{encodeBase64((deposit.id * 1000).toString().replace("=", ""))}</td> */}
                              {/* <td className="text-start">{deposit.payinMethodData.name}</td> */}
                              <td className="text-start">
                                <span className={`fs-6 badge badge-${statusDeposit[deposit.status].color}`}>
                                  {statusDeposit[deposit.status].text}
                                </span>
                              </td>
                              <td className="text-start">{masks.dollarCurrency(deposit.value)}</td>
                              <td className="text-start">{masks.dollarCurrency(deposit.value_fee)}</td>
                              {/* <td className="text-start">{masks.dollarCurrency(deposit.valuePaid)}</td> */}
                              <td className="text-start">{deposit.createdAt ? dayjs(deposit.createdAt).format('DD/MM/YYYY [ás] HH:mm:ss') : '00/00/00 ás 00:00:00'}</td>
                            </tr>
                          ))
                          ) : (
                            <tr>
                              <td colSpan={8} className="text-center">Nenhum registro encontrado.</td>
                            </tr>
                          )
                        )}

                          {category === "withdraws" && (
                            withdraws?.result && withdraws.result.length > 0 ? (
                              withdraws.result.map((withdraw) => (
                                <tr key={withdraw.id}>
                                  <th scope="row" className="text-start">{withdraw.id}</th>
                                  <td className="text-start">{withdraw?.address_to}</td>
                                  <td className="text-start">
                                    {withdraw?.address_from }
                                  </td>
                                  <td className="text-start">{withdraw?.transactionHash}</td>
                                  <td className="text-start">
                                    <span className={`fs-6 badge badge-${statusWithdraw[withdraw.status]?.color || 'secondary'}`}>
                                      {statusWithdraw[withdraw.status]?.text || 'Indefinido'}
                                    </span>
                                  </td>
                                  <td className="text-start">{masks.dollarCurrency(withdraw.value)}</td>
                                  <td className="text-start">{masks.dollarCurrency(withdraw.value_fee)}</td>
                                  <td className="text-start">
                                    {withdraw.createdAt ?
                                      dayjs(withdraw.createdAt).format('DD/MM/YYYY [ás] HH:mm:ss') :
                                      '00/00/00 ás 00:00:00'}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={8} className="text-center">Nenhum registro encontrado.</td>
                              </tr>
                            )
                          )}

                          {category === "swaps" && (
                            swap?.result && swap.result.length > 0 ? (
                              swap.result.map((swap: any) => (
                                <tr key={swap.contract_id_received}>
                                  <td className="text-start">{swap?.id}</td>
                                  <td className="text-start">
                                    {swap.currency}
                                  </td>
                                  <td className="text-start">
                                    {swap.currency !== 'USDT' ? "USDT" : "Coin"}
                                  </td>
                                  <td className="text-start">{masks.coinCurrency(swap.value)}</td>
                                  <td className="text-start">{masks.coinCurrency(swap.transactionFee)}</td>
                                  <td className="text-start">{dayjs(swap.createdAt).format('DD/MM/YYYY [às] HH:mm:ss')}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={10} className="text-center">Nenhum registro encontrado.</td>
                              </tr>
                            )
                          )}

                      </>
                    )}
                  </tbody>

                </table>
                <Pagination setPage={setPage} page={page} totalPages={totalPages} maxPageShow={2} />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  )
}
