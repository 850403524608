/* eslint-disable jsx-a11y/anchor-is-valid */
import Logo from '@assets/img/logo.svg';
import { FaList, FaPaintRoller, FaUser, FaWallet } from 'react-icons/fa6';

import { useSidebarCollapse } from '@hooks/contexts/SidebarCollapseContext';
import React, { useEffect, useRef, useState, } from 'react';
import { FaClipboard, FaFolderOpen, FaHome } from 'react-icons/fa';

import { NavLink, useLocation } from 'react-router-dom';

export default function NavbarLeft() {
    const { isCollapsed, toggleSidebar, handleMouseEnter, handleMouseLeave } = useSidebarCollapse();
    const location = useLocation();
    const isActive = (paths: string[]) => paths.some(path => location.pathname.startsWith(path));

    // let submenu active only when selected
    const [activeSubmenuHistoric, setActiveSubmenuHistoric] = useState(false);
    const [activeSubmenuLogs, setActiveSubmenuLogs] = useState(false);
    const [activeSubmenuBalance, setActiveSubmenuBalance] = useState(false);

    function handleClickHistoric(e: React.MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();
        setActiveSubmenuHistoric(prevState => !prevState);
        setActiveSubmenuLogs(false);
        setActiveSubmenuBalance(false);
    };

    function handleClickLogs(e: React.MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();
        setActiveSubmenuLogs(prevState => !prevState);
        setActiveSubmenuHistoric(false);
        setActiveSubmenuBalance(false);
    };

    function handleClickBalance(e: React.MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();
        setActiveSubmenuBalance(prevState => !prevState);
        setActiveSubmenuHistoric(false);
        setActiveSubmenuLogs(false);
    };

    // minimize dropdown when the route change
    const historicRef = useRef<HTMLDivElement>(null);
    const logsRef = useRef<HTMLDivElement>(null);
    const BalanceRef = useRef<HTMLDivElement>(null);

    // routes that dont close the dropdown of historic
    const submenuPathsHistoric = ['/historic/withdraw', '/historic/deposits', '/historic/bets', '/approvals', '/redemptions'];
    // routes that dont close the dropdown of logs
    const submenuPathsLogs = ['/logs/admin', '/logs/customer'];
    // routes that dont close the dropdown of balance
    const submenuPathsBalance = ['/approvals', '/redemptions', '/withdraw/cash', '/addition/token', '/remove/token', '/addition/coin', '/remove/coin'];

    useEffect(() => {
        if (!submenuPathsHistoric.some(path => location.pathname.startsWith(path))) {
            if (historicRef.current) {
                historicRef.current.classList.remove('show');
                historicRef.current.classList.add('collapsing');
                historicRef.current.classList.remove('collapsing');
            }
            setActiveSubmenuHistoric(false);
        }
        if (!submenuPathsLogs.some(path => location.pathname.startsWith(path))) {
            if (logsRef.current) {
                logsRef.current.classList.remove('show');
                logsRef.current.classList.add('collapsing');
                logsRef.current.classList.remove('collapsing');

            }
            setActiveSubmenuLogs(false);
        }
        if (!submenuPathsBalance.some(path => location.pathname.startsWith(path))) {
            if (BalanceRef.current) {
                BalanceRef.current.classList.remove('show');
                BalanceRef.current.classList.add('collapsing');
                BalanceRef.current.classList.remove('collapsing');

            }
            setActiveSubmenuBalance(false);
        }

    }, [location.pathname]);

    return (
        <>
            <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} data-background-color="dark">
                <div className="sidebar-logo">
                    <div className="logo-header" data-background-color="dark">
                        <NavLink to="/" className=" logo">
                            <img
                                src={Logo}
                                alt="navbar brand"
                                className="navbar-brand"
                                height="20"
                            />
                        </NavLink>
                        <div className="nav-toggle" >
                            <button className="btn btn-toggle toggle-sidebar" onClick={toggleSidebar}>
                                <i className="gg-menu-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="sidebar-wrapper scrollbar scrollbar-inner">
                    <div className="sidebar-content">
                        <ul className="nav nav-secondary">

                            <li className={`nav-item`} >
                                <NavLink to="/" className="link">
                                    <div className="icon">
                                        <FaHome />
                                    </div>
                                    <p>Dashboard</p>
                                </NavLink>
                            </li>


                            <li className="nav-section">
                                <span className="sidebar-mini-icon">
                                    <i className="fa fa-ellipsis-h"></i>
                                </span>
                                <h4 className="text-section">Usuários</h4>
                            </li>

                            <li className={`nav-item ${isActive(['/users']) ? 'active' : ''}`}>
                                <NavLink to="/users" className="link">
                                    <div className="icon">
                                        <FaUser />
                                    </div>
                                    <p>Usuários</p>
                                </NavLink>
                            </li>

                            <li className="nav-section">
                                <span className="sidebar-mini-icon">
                                    <i className="fa fa-ellipsis-h"></i>
                                </span>
                                <h4 className="text-section">Financeiro</h4>
                            </li>

                            <li className={`nav-item ${isActive(['/financial/general']) ? 'active' : ''}`}>
                                <NavLink to="/financial/general" className="link">
                                    <div className="icon">
                                        <FaWallet />
                                    </div>
                                    <p>Extrato Geral</p>
                                </NavLink>
                            </li>

                            <li className="nav-section">
                                <span className="sidebar-mini-icon">
                                    <i className="fa fa-ellipsis-h"></i>
                                </span>
                            </li>

                            <li className={`nav-item ${isActive(['/historic/withdraw', '/historic/deposits', '/historic/bets']) ? 'active' : ''} ${activeSubmenuHistoric ? 'submenu' : ''}`}>
                                <a data-bs-toggle="collapse" href="#historic" onClick={handleClickHistoric}>
                                    <div className="icon">
                                        <FaFolderOpen />
                                    </div>
                                    <p>Históricos</p>
                                    <span className="caret"></span>
                                </a>
                                <div className={`collapse ${activeSubmenuHistoric ? 'collapsing show' : ''}`} id="historic" ref={historicRef}>
                                    <ul className="nav nav-collapse">
                                        <li className={`link ${isActive(['/historic/withdraw']) ? 'active' : ''}`}>
                                            <NavLink to="/historic/withdraw">
                                                <span className="sub-item">Saques</span>
                                            </NavLink>
                                        </li>
                                        <li className={`link ${isActive(['/historic/deposits']) ? 'active' : ''}`}>
                                            <NavLink to="/historic/deposits">
                                                <span className="sub-item">Depósitos</span>
                                            </NavLink>
                                        </li>
                                        <li className={`link ${isActive(['/historic/bets']) ? 'active' : ''}`}>
                                            <NavLink to="/historic/bets">
                                                <span className="sub-item">Apostas</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className="nav-section">
                                <span className="sidebar-mini-icon">
                                    <i className="fa fa-ellipsis-h"></i>
                                </span>
                            </li>

                            <li className={`nav-item ${isActive(['/approvals', '/redemptions', '/withdraw/cash', '/addition/cash', '/remove/cash']) ? 'active' : ''} ${activeSubmenuBalance ? 'submenu' : ''}`}>
                                <a data-bs-toggle="collapse" href="#balance" onClick={handleClickBalance}>
                                    <div className="icon">
                                        <FaList />
                                    </div>
                                    <p>Aprovações</p>
                                    <span className="caret"></span>
                                </a>
                                <div className={`collapse ${activeSubmenuBalance ? 'collapsing show' : ''}`} id="balance" ref={BalanceRef}>
                                    <ul className="nav nav-collapse">
                                        {/* <li className={`link ${isActive(['/withdraw/cash']) ? 'active' : ''}`}>
                                            <NavLink to="/withdraw/cash">
                                                <span className="sub-item">Aprovação de Saque</span>
                                            </NavLink>
                                        </li> */}

                                        <li className={`link ${isActive(['/addition/token']) ? 'active' : ''}`}>
                                            <NavLink to="/addition/token">
                                                <span className="sub-item">Adição de Token</span>
                                            </NavLink>
                                        </li>
                                        <li className={`link ${isActive(['/addition/coin']) ? 'active' : ''}`}>
                                            <NavLink to="/addition/coin">
                                                <span className="sub-item">Adição de Coin</span>
                                            </NavLink>
                                        </li>
                                        <li className={`link ${isActive(['/remove/token']) ? 'active' : ''}`}>
                                            <NavLink to="/remove/token">
                                                <span className="sub-item">Remoção de Token</span>
                                            </NavLink>
                                        </li>
                                        <li className={`link ${isActive(['/remove/coin']) ? 'active' : ''}`}>
                                            <NavLink to="/remove/coin">
                                                <span className="sub-item">Remoção de Coin</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>


                            <li className="nav-section">
                                <span className="sidebar-mini-icon">
                                    <i className="fa fa-ellipsis-h"></i>
                                </span>
                                <h4 className="text-section">Logs</h4>
                            </li>

                            <li className={`nav-item ${isActive(['/logs/admin', '/logs/customer']) ? 'active' : ''} ${activeSubmenuLogs ? 'submenu' : ''}`}>
                                <a data-bs-toggle="collapse" href="#logs" onClick={handleClickLogs}>
                                    <div className="icon">
                                        <FaClipboard />
                                    </div>
                                    <p>Logs</p>
                                    <span className="caret"></span>
                                </a>
                                <div className={`collapse ${activeSubmenuLogs ? 'collapsing show' : ''}`} id="logs" ref={logsRef}>
                                    <ul className="nav nav-collapse">
                                        <li className={`link ${isActive(['/logs/admin']) ? 'active' : ''}`}>
                                            <NavLink to="/logs/admin">
                                                <span className="sub-item">Administrador</span>
                                            </NavLink>
                                        </li>
                                        <li className={`link ${isActive(['/logs/customer']) ? 'active' : ''}`}>
                                            <NavLink to="/logs/customer">
                                                <span className="sub-item">Usuários</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            {/* <li className="nav-section">
                                <span className="sidebar-mini-icon">
                                    <i className="fa fa-ellipsis-h"></i>
                                </span>
                                <h4 className="text-section">jogos</h4>
                            </li>

                            <li className={`nav-item ${isActive(['/games']) ? 'active' : ''}`}>
                                <NavLink to="/games" className="link">
                                    <div className="icon">
                                        <FaGamepad />
                                    </div>
                                    <p>Jogos</p>
                                </NavLink>
                            </li> */}

                            <li className="nav-section">
                                <span className="sidebar-mini-icon">
                                    <i className="fa fa-ellipsis-h"></i>
                                </span>
                                <h4 className="text-section">Configurações</h4>
                            </li>

                            <li className={`nav-item ${isActive(['/customization']) ? 'active' : ''}`}>
                                <NavLink to="/customization" className="link">
                                    <div className="icon">
                                        <FaPaintRoller />
                                    </div>
                                    {/* <p>Personalização</p> */}
                                    {/* <p>Layout</p> */}
                                    {/* <p>Configuração Visual</p> */}
                                    <p>Customização</p>
                                </NavLink>
                            </li>

                        </ul>
                    </div>
                </div>

            </div >
        </>
    )
}