import { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    showLabel?: boolean;
    animated?: boolean;
    placeholder?: string;
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}


export function InputSearch({
    label,
    showLabel,
    placeholder,
    className = 'mb-3',
    animated,
    type = 'text',
    value,
    onChange,
    ...rest
}: IProps) {

    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = e.target.value;
        e.target.value = newValue;

        if (onChange) {
            onChange(e);
        }
    };

    return (
        <>
            {animated ? (
                <div className={`form-floating ${className} input-${type} mb-3`}>
                    <input
                        className={`form-control`}
                        placeholder={placeholder}
                        type={type === 'password' && showPassword ? 'text' : type ?? 'text'}
                        {...rest}
                        onChange={handleChange}
                        value={value}
                        autoComplete="false"
                    />
                    <label>{label}</label>
                    {
                        type === 'password' && (
                            <button
                                className="border-0"
                                onClick={() => {
                                    setShowPassword(!showPassword);
                                }}
                            >
                                {showPassword ? <FiEye /> : <FiEyeOff />}
                            </button>
                        )
                    }
                </div>
            ) : (
                <div>
                    {label && <label className="form-label">{label}</label>}
                    <div className={`${className} input-${type}`}>
                        <input
                            className={`form-control`}
                            placeholder={placeholder}
                            type={type === 'password' && showPassword ? 'text' : type ?? 'text'}
                            {...rest}
                            onChange={handleChange}
                            value={value}
                        />
                    </div>
                </div>
            )}
        </>
    );
}
