import { Card } from "@components/cards/card";
import { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { Banner } from "./sub-pages/Banner";
import { Theme } from "./sub-pages/Theme";
import { Roulette } from "./sub-pages/Roulette";

export function Customization() {
  type Category = keyof typeof descriptions;
  const descriptions: Record<'banner' | 'theme' | 'roulette', string> = {
    banner: `Aqui você pode personalizar o(s) banner(s) da Página Inicial.`,
    theme: `Em breve poderá alterar características da aparência da plataforma por aqui.`,
    roulette: `Aqui você pode personalizar o valor de compra da roleta.`,
  };
  const [category, setCategory] = useState<Category>('banner');
  const [description, setDescription] = useState<string>(descriptions[category]);

  function getCategory(category: Category) {
    setDescription(descriptions[category as Category]);
    setCategory(category);
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Card className="mb-2">
            <div className="card-header">
              <div className="row ">
                <div className="col-md-10">
                  <ul className="nav nav-pills nav-success" id="pills-tab" role="tablist">
                    <li className="nav-item submenu" role="presentation">
                      <a className="nav-link active" id="pills-banners-tab" data-bs-toggle="pill" href="#pills-banners" role="tab" onClick={() => getCategory("banner")} aria-controls="pills-banners" aria-selected="true">Banners</a>
                    </li>
                    <li className="nav-item submenu" role="presentation">
                      <a className="nav-link" id="pills-theme-tab" data-bs-toggle="pill" href="#pills-theme" role="tab" onClick={() => getCategory("theme")} aria-controls="pills-theme" aria-selected="false">Temas</a>
                    </li>
                    <li className="nav-item submenu" role="presentation">
                      <a className="nav-link" id="pills-theme-tab" data-bs-toggle="pill" href="#pills-roulette" role="tab" onClick={() => getCategory("roulette")} aria-controls="pills-theme" aria-selected="false">Roleta</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-2 text-end">
                  <button className="btn btn-primary ">
                    <FaInfoCircle />
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <p className="card-category">{description}</p>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="tab-content mt-2 mb-3" id="pills-tabContent">
            <div className="tab-pane fade active show" id="pills-banners" role="tabpanel" aria-labelledby="pills-banners-tab">
              <Banner />
            </div>
            <div className="tab-pane fade" id="pills-theme" role="tabpanel" aria-labelledby="pills-theme-tab">
              <Theme />
            </div>
            <div className="tab-pane fade" id="pills-roulette" role="tabpanel" aria-labelledby="pills-roulette-tab">
              <Roulette />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}