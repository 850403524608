/* eslint-disable react-hooks/exhaustive-deps */
import Default from "@assets/img/defaultAvatar.png";
import { Card } from "@components/cards/card";
import { CardInfo } from "@components/cards/card-info";
import { ContentBetweenDollar } from "@components/contents/content-between-dollar";
import { LoadingSkeleton } from "@components/skeleton-loading";
import { IBiggestBalances, IDates, IsLoading, ITokenDepositsAndWithdrawsData, IUserData, IUserMoney } from "@hooks/dtos/IHome";
import { Masks } from "@hooks/functions/Masks/Masks";
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";
import { api } from "@service/api";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

interface IMoneyData {
    type: string;
    total: string;
}


const masks = new Masks();
export function HomeToken() {
    const [usersData, setUsersData] = useState<IUserData>({} as IUserData);
    const [moneyData, setmoneyData] = useState<IUserMoney>({} as IUserMoney);
    const [depositsData, setDepositsData] = useState<ITokenDepositsAndWithdrawsData[]>([]);
    const [withdrawsData, setWithdrawsData] = useState<ITokenDepositsAndWithdrawsData[]>([]);
    const [usersBiggestBalance, setUsersBiggestBalance] = useState<IBiggestBalances[]>([]);
    const [isDepositsLoading, setIsDepositsLoading] = useState(true);
    const [isWithdrawsLoading, setIsWithdrawsLoading] = useState(true);
    const [isBiggestBalancesLoading, setIsBiggestBalancesLoading] = useState(true);
    const { actions: { pushToastify } } = ZuToastifyStore()

    const [isLoading, setIsLoading] = useState<IsLoading>({
        now: true,
        week: true,
        monthly: true,
        year: true,
    });

    const week = new Date();
    const today = new Date();
    const dates = {
        now: dayjs(today).format('YYYY-MM-DD'),
        week: dayjs(new Date(week.setDate(week.getDate() - week.getDay()))).format('YYYY-MM-DD'),
        month: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format('YYYY-MM-DD'),
        year: dayjs(new Date(today.getFullYear(), 0, 1)).format('YYYY-MM-DD')
    };

    const formatAddress = (address: string): string => {
    if (address) return `${address.slice(0, 5)}...${address.slice(-4)}`

    return ''
  }

    async function getMetrics() {
        try {
            const keys: (keyof IDates)[] = ["now", "week", "month", "year"];
            for (let key of keys) {
                const [users, money] = await Promise.all([
                    api.post("/manager/metrics/users/dashboard", {
                        startDate: dates[key]
                    }),
                    api.post("/manager/metrics/money/token/dashboard/total", {
                        type: ['deposit', 'withdrawal'],
                        contract: 1,
                        startDate: dates[key]
                    })
                ]);
                const moneyData: IMoneyData[] = money.data;

                const deposit = moneyData.find((item: IMoneyData) => item.type === "deposit")?.total || 0;
                const withdrawal = moneyData.find((item: IMoneyData) => item.type === "withdrawal")?.total || 0;

                setUsersData((prev) => ({
                    ...prev,
                    [key]: users.data
                }));

                setmoneyData((prev) => ({
                    ...prev,
                    [key]: {
                        deposit: Number(deposit),
                        withdrawal: Number(withdrawal),
                    }
                }));

                setIsLoading((prev) => ({
                    ...prev,
                    [key]: false
                }));
            }
        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro!",
                type: 'error',
                theme: 'dark',
            });
            setIsLoading((prev) => ({
                ...prev,
                now: false,
                week: false,
                month: false,
                year: false
            }));
        }
    }

    async function getDeposits() {
        try {
            const result = await api.post("/manager/metrics/recents/withdraws/deposits/token", {
                contract: 1,
                op: 1
            });
            setDepositsData(result.data);
        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro!",
                type: 'error',
                theme: 'dark',
            });
        }
        setIsDepositsLoading(false);
        setIsWithdrawsLoading(false);

    }

    async function getWithdraws() {
        try {
            const result = await api.post("/manager/metrics/recents/withdraws/deposits/token",{
                contract: 1,
                op: 2
            });
            setWithdrawsData(result.data);
        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro!",
                type: 'error',
                theme: 'dark',
            });
        }
        setIsDepositsLoading(false);
        setIsWithdrawsLoading(false);

    }

    async function getBiggestBalances() {
        try {
            const result = await api.post("/manager/metrics/biggest/balance/token", { 
                contract: 1
            });
            setUsersBiggestBalance(result.data);
        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro!",
                type: 'error',
                theme: 'dark',
            });
        }
        setIsBiggestBalancesLoading(false);
    }

    useEffect(() => {
        const fetchData = () => {
            getMetrics();
            getBiggestBalances();
            getDeposits();
            getWithdraws();
        };        
        fetchData();
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <Card className="position-relative">
                        <CardInfo
                            isLoading={isLoading.now}
                            title="Usuários"
                            value={String(usersData?.month || 0)}
                        />
                        <span className="position-absolute p-2 end-0 bg-info text-white rounded-end rounded-bottom-0">
                            Mensal
                        </span>
                    </Card>
                </div>

                <div className="col-md-4">
                    <Card>
                        <CardInfo
                            isLoading={isLoading.now}
                            title="Total de Depósitos"
                            coin="dollar"
                            value={Number(moneyData?.month?.["deposit"] || 0)}
                        />
                        <span className="position-absolute end-0 bg-info text-white p-2 rounded-end rounded-bottom-0">
                            Mensal
                        </span>
                    </Card>
                </div>

                <div className="col-md-4">
                    <Card>
                        <CardInfo
                            isLoading={isLoading.now}
                            title="Total de Saques"
                            coin="dollar"
                            value={Number(moneyData?.month?.["withdrawal"] || 0)}
                        />
                        <span className="position-absolute end-0 bg-info text-white p-2 rounded-end rounded-bottom-0">
                            Mensal
                        </span>
                    </Card>
                </div>

                {/* <div className="col-md-3">
                    <Card>
                        <CardInfo
                            isLoading={isLoading.now}
                            title="Total de Bonificações"
                            value={Number(moneyData?.month?.["1"] || 0)}
                        />
                        <span className="position-absolute end-0 bg-info p-2 rounded-end rounded-bottom-0 text-white">
                            Mensal
                        </span>
                    </Card>
                </div> */}
            </div>

            <div className="row">
                <div className="col-md-4">
                    <Card className="position-relative">
                        <div className="card-header">
                            <div className="card-title">
                                Resumo geral
                            </div>
                            <span className="position-absolute top-0 end-0 bg-info text-white p-2 rounded-bottom-0 rounded-end">
                                Diário
                            </span>
                        </div>
                        <div className="card-body">
                            <ContentBetweenDollar isLoading={isLoading.now} title="Usuários" value={String(usersData?.now || 0)} />
                            <ContentBetweenDollar isLoading={isLoading.now} title="Depósitos" value={Number(moneyData?.now?.["deposit"] || 0)} />
                            <ContentBetweenDollar isLoading={isLoading.now} title="Saques" value={Number(moneyData?.now?.["withdrawal"] || 0)} />
                        </div>
                    </Card>
                </div>

                <div className="col-md-4">
                    <Card className="position-relative">
                        <div className="card-header">
                            <div className="card-title">
                                Resumo geral
                            </div>
                            <span className="position-absolute top-0 end-0 bg-info text-white p-2 rounded-bottom-0 rounded-end">
                                Semanal
                            </span>
                        </div>
                        <div className="card-body">
                            <ContentBetweenDollar isLoading={isLoading.week} title="Usuários" value={String(usersData?.week ?? 0)} />
                            <ContentBetweenDollar isLoading={isLoading.week} title="Depósitos" value={Number(moneyData?.week?.["deposit"] || 0)} />
                            <ContentBetweenDollar isLoading={isLoading.week} title="Saques" value={Number(moneyData?.week?.["withdrawal"] || 0)} />
                            {/* <ContentBetweenDollar isLoading={isLoading.week} title="Bonificações" value={Number(moneyData?.week?.["1"] || 0)} /> */}
                            {/* <ContentBetweenDollar isLoading={isLoading.week} title="Apostas" value={Number(moneyData?.week?.["56"] || 0)} /> */}
                        </div>
                    </Card>
                </div>

                <div className="col-md-4">
                    <Card className="position-relative">
                        <div className="card-header">
                            <div className="card-title">
                                Resumo geral
                            </div>
                            <span className="position-absolute top-0 end-0 bg-info text-white p-2 rounded-bottom-0 rounded-end">
                                Anual
                            </span>
                        </div>
                        <div className="card-body">
                            <ContentBetweenDollar isLoading={isLoading.year} title="Usuários" value={String(usersData?.year ?? 0)} />
                            <ContentBetweenDollar isLoading={isLoading.year} title="Depósitos" value={Number(moneyData?.year?.["deposit"] || 0)} />
                            <ContentBetweenDollar isLoading={isLoading.year} title="Saques" value={Number(moneyData?.year?.["withdrawal"] || 0)} />
                            {/* <ContentBetweenDollar isLoading={isLoading.year} title="Bonificações" value={Number(moneyData?.year?.["1"] || 0)} /> */}
                            {/* <ContentBetweenDollar isLoading={isLoading.year} title="Apostas" value={Number(moneyData?.year?.["56"] || 0)} /> */}
                        </div>
                    </Card>
                </div>
            </div>

            <div className="row">
                <div className="col-md-4">
                    <Card className="scroll h-100">
                        <div className="card-header">
                            <div className="card-title">
                                <div className="row align-items-center">
                                    <div className="col-md-9">
                                        <span>Maiores Saldos</span>
                                    </div>                                    
                                </div>
                            </div>
                        </div>

                        <div className="card-body text-center">
                            {isBiggestBalancesLoading ? (
                                <div>
                                    <LoadingSkeleton count={6} />
                                </div>
                            ) : usersBiggestBalance.length > 0 ? (
                                usersBiggestBalance.map((user) => (
                                    <div key={user.customer} className="row mb-3 border-bottom border-2 pb-2 align-items-center gap-md-0 gap-2">
                                        <div className="col-md-4 text-start">
                                            <div className="avatar avatar-lg">
                                                <img src={user?.photos ?? Default} alt="" className="avatar-img rounded-circle" />
                                            </div>
                                        </div>
                                        <div className="col-md-4 flex-column text-start gap-2">
                                            <span className="text-truncate">{user.userName}</span>
                                        </div>
                                        <div className="col-md-4 d-flex flex-column text-center gap-2">
                                            <span className="fw-bold">Saldo</span>
                                            <span>{masks.cryptoCurrency(user.balance)} <small className="text-muted">USDT</small></span>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <span>Nenhum registro encontrado.</span>
                            )}
                        </div>
                    </Card>
                </div>

                <div className="col-md-4">
                    <Card className="scroll h-100">
                        <div className="card-header">
                            <div className="card-title">
                                <span>Depósitos Recentes</span>
                            </div>
                        </div>
                        <div className="card-body text-center">
                            {isDepositsLoading ? (
                                <div>
                                    <LoadingSkeleton count={7} />
                                </div>
                            ) : depositsData.length > 0 ? (
                                depositsData.map((deposit) => (                                    
                                    <div key={deposit.id} className="border-bottom border-2 pb-2 ">
                                        <div className="row align-items-center gap-md-0 gap-2">
                                            <div className="col-md-2 text-center text-md-start">
                                                <div className="avatar avatar-lg">
                                                    <img src={deposit?.photos ?? Default} alt="" className="avatar-img rounded-circle" />
                                                </div>
                                            </div>
                                            <div className="col-md-4 gap-2 text-start">
                                                <span className="text-truncate">{deposit.userName}</span>
                                                <div>
                                                Hash: <a href={`https://polygonscan.com/tx/${deposit.transactionHash}`} target="_blank" rel="noreferrer">{formatAddress(deposit.transactionHash)}</a>
                                                </div>
                                            </div>
                                            <div className="col-md-3 gap-2">
                                                <span>{masks.cryptoCurrency(deposit.value)} <small className="text-muted">USDT</small></span>                                            
                                                <span className="badge badge-success ms-md-0 me-auto">Aprovado</span>
                                            </div>
                                            <div className="col-md-3 gap-2">
                                                <span>{dayjs((deposit.updateAt ?? deposit.createdAt)).format("DD/MM/YYYY HH:mm")}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div>
                                    <span>Nenhum registro encontrado.</span>

                                </div>
                            )}
                        </div>
                    </Card>
                </div>

                <div className="col-md-4">
                    <Card className="scroll h-100">
                        <div className="card-header">
                            <div className="card-title">
                                <span>Retiradas Recentes</span>
                            </div>
                        </div>
                        <div className="card-body text-center">
                            {isWithdrawsLoading ? (
                                <div>
                                    <LoadingSkeleton count={7} />
                                </div>
                            ) : withdrawsData?.length > 0 ? (
                                withdrawsData.map((withdraw) => (
                                    <div key={withdraw.id} className="border-bottom border-2 pb-2 ">
                                        <div className="row align-items-center gap-md-0 gap-2">
                                            <div className="col-md-2 text-center text-md-start">
                                                <div className="avatar avatar-lg">
                                                    <img src={withdraw?.photos ?? Default} alt="" className="avatar-img rounded-circle" />
                                                </div>
                                            </div>
                                            <div className="col-md-4 gap-2 text-start">
                                                <span className="text-truncate">{withdraw.userName}</span>
                                                <div>
                                                Hash: <a href={`https://polygonscan.com/tx/${withdraw.transactionHash}`} target="_blank" rel="noreferrer">{formatAddress(withdraw.transactionHash)}</a>
                                                </div>
                                            </div>
                                            <div className="col-md-3 gap-2">
                                                <span>{masks.cryptoCurrency(withdraw.value)} <small className="text-muted">USDT</small></span>                                            
                                                <span className="badge badge-success ms-md-0 me-auto">Aprovado</span>
                                            </div>
                                            <div className="col-md-3 gap-2">
                                                <span>{dayjs((withdraw.updateAt ?? withdraw.createdAt)).format("DD/MM/YYYY HH:mm")}</span>
                                            </div>
                                        </div>
                                    </div>                                    
                                ))
                            ) : (
                                <span>Nenhum registro encontrado.</span>
                            )}
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
}