import { Card } from "@components/cards/card";
import { useEffect, useState } from "react";

import { api } from "@service/api";
import dayjs from "dayjs";

import { Pagination } from "@components/pagination";
import { LoadingSkeleton } from "@components/skeleton-loading";
import { IBetsDTO } from "@hooks/dtos/IBetDto";
import { Masks } from "@hooks/functions/Masks/Masks";
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";

const status = {
  p: {
    color: "info",
    text: "Pendente",
  },
  w: {
    color: "success",
    text: "Ganho"
  },
  hw: {
    color: "success",
    text: "Ganho parcial"
  },
  l: {
    color: "warning",
    text: "Perca"
  },
  hl: {
    color: "warning",
    text: "Perca parcial"
  },
  x: {
    color: "danger",
    text: "Cancelado",
  },
}

const masks = new Masks();
export function Bets() {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [bets, setBets] = useState<IBetsDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { actions: { pushToastify } } = ZuToastifyStore()

  async function historicDeposits(page: number) {
    try {
      const response = await api.post(`/manager/bets/list`, {
        page: page,
      });
      setBets(response.data.result);
      setTotalPages(response.data.pages);
    } catch (err: any) {
      pushToastify({
        message: err?.response?.data.message ?? "Ocorreu um erro!",
        type: 'error',
        theme: 'dark',
      });    }
    setIsLoading(false);
  }
  

  useEffect(() => {
    historicDeposits(page);
  }, [page]);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Card className="card-round">
            <div className="card-header">
              <div className="row">
                <div className="col-md-10">
                  <h4 className="card-title">Apostas</h4>
                  <p className="card-category">Lista de todos os apostas</p>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <div id="basic-datatables_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                  <div id="basic-datatables_length" className="dataTables_length">
                    <table className="table table-striped">
                      <thead>
                        <tr role="row">
                          <th scope="col" className="fs-6">#</th>
                          {/* <th>Game</th> */}
                          <th>Round</th>
                          <th>Aposta</th>
                          <th>Saldo</th>
                          <th>Profit</th>
                          <th>Taxa da casa</th>
                          <th>Taxa do Provedor</th>
                          <th>Status</th>
                          <th>Data da aposta</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan={10}>
                              <LoadingSkeleton />
                            </td>
                          </tr>
                        ) : bets.length > 0 ? (
                          bets.map((bet, index) => (
                            <tr key={index}>
                              <th scope="row" className="text-start">{bet.id}</th>
                              <td className="text-start">{bet.round}</td>
                              <td className="text-start">{masks.coinCurrency(bet.amount)}</td>
                              <td className="text-start">{masks.coinCurrency(bet.balance)}</td>
                              <td className="text-start">{masks.coinCurrency(bet.profit)}</td>
                              <td className="text-start">{masks.coinCurrency(bet.houseFee)}</td>
                              <td className="text-start">{masks.coinCurrency(bet.providerFee)}</td>
                              <td className="text-start">
                                <span className={`fs-6 badge badge-${status[bet.status].color}`}>
                                  {status[bet.status].text}
                                </span>
                              </td>
                              <td className="text-start">{dayjs(bet.createdAt).format('DD/MM/YYYY [ás] HH:mm:ss')}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={10} className="text-center">Nenhum registro encontrado.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <Pagination page={page} setPage={setPage} totalPages={totalPages} maxPageShow={3} />
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}