import Default from "@assets/img/defaultAvatar.png";
import { Card } from "@components/cards/card";
import { CardInfo } from "@components/cards/card-info";
import { ContentBetween } from "@components/contents/content-between";
import { LoadingSkeleton } from "@components/skeleton-loading";
import { IBiggestBalances, IDates, IDepositData, IsLoading, IUserData, IUserMoney, IWithdrawData } from "@hooks/dtos/IHome";
import { Masks } from "@hooks/functions/Masks/Masks";
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";
import { api } from "@service/api";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const masks = new Masks();
export function HomeReal() {
    const [usersData, setUsersData] = useState<IUserData>({} as IUserData);
    const [moneyData, setmoneyData] = useState<IUserMoney>({} as IUserMoney);
    const [depositsData, setDepositsData] = useState<IDepositData[]>([]);
    const [withdrawsData, setWithdrawsData] = useState<IWithdrawData[]>([]);
    const [usersBiggestBalance, setUsersBiggestBalance] = useState<IBiggestBalances[]>([]);
    const [isDepositsLoading, setIsDepositsLoading] = useState(true);
    const [isWithdrawsLoading, setIsWithdrawsLoading] = useState(true);
    const [isBiggestBalancesLoading, setIsBiggestBalancesLoading] = useState(true);
    const [locked, setLocked] = useState<"y" | "n" | "">("");
    const { actions: { pushToastify } } = ZuToastifyStore()

    const [isLoading, setIsLoading] = useState<IsLoading>({
        now: true,
        week: true,
        monthly: true,
        year: true,
    });

    const week = new Date();
    const today = new Date();
    const dates = {
        now: dayjs(today).format('YYYY-MM-DD'),
        week: dayjs(new Date(week.setDate(week.getDate() - week.getDay()))).format('YYYY-MM-DD'),
        month: dayjs(new Date(today.getFullYear(), today.getMonth(), 1)).format('YYYY-MM-DD'),
        year: dayjs(new Date(today.getFullYear(), 0, 1)).format('YYYY-MM-DD')
    }

    async function getMetrics() {
        try {
            const keys: (keyof IDates)[] = ["now", "week", "month", "year"];
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                const [users, money] = await Promise.all([
                    api.post("/manager/metrics/users/dashboard", {
                        startDate: dates[key]
                    }),
                    api.post("/manager/metrics/money/dashboard", {
                        ops: [1, 54, 56, 58],
                        startDate: dates[key]
                    })
                ])

                setUsersData((prev) => {
                    prev[key] = users.data;
                    return { ...prev };
                });

                setmoneyData((prev) => {
                    prev[key] = money.data;
                    return { ...prev };
                });

                setIsLoading((prev) => ({ ...prev, [key]: false }));
            }
        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro!",
                type: 'error',
                theme: 'dark',
            });
            setIsLoading((prev) => ({ ...prev, now: false, week: false, monthly: false, year: false }));

        }
    }

    async function getDepositsAndWithdraws() {
        try {
            const result = await api.post("/manager/metrics/recents/list");
            setDepositsData(result.data.refactorOrders);
            setWithdrawsData(result.data.refactorPayouts);
        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro!",
                type: 'error',
                theme: 'dark',
            });
        }
        setIsDepositsLoading(false);
        setIsWithdrawsLoading(false);

    }

    async function getBiggestBalances(locked?: "y" | "n" | "") {
        try {
            const result = await api.post("/manager/cash/biggest/balances", { locked });
            setUsersBiggestBalance(result.data);
        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro!",
                type: 'error',
                theme: 'dark',
            });
        }
        setIsBiggestBalancesLoading(false);
    }

    function changeBalanceList(e: React.ChangeEvent<HTMLSelectElement>) {
        const value = e.target.value as "y" | "n" | "";
        if (value === "y" || value === "n" || value.length === 0) setLocked(value);
    }

    useEffect(() => {
        setIsBiggestBalancesLoading(true);
        const interval = setTimeout(() => {
            getBiggestBalances(locked);
        }, 1500)
        return () => clearTimeout(interval);
    }, [locked]);

    useEffect(() => {
        getMetrics();
    }, []);

    useEffect(() => {
        getDepositsAndWithdraws();
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-md-3">
                    <Card className="position-relative">
                        <CardInfo
                            isLoading={isLoading.now}
                            title="Usuários"
                            value={String(usersData?.now || 0)}
                        />
                        <span className="position-absolute p-2 end-0 bg-info text-white rounded-end rounded-bottom-0">
                            Mensal
                        </span>
                    </Card>
                </div>

                <div className="col-md-3">
                    <Card>
                        <CardInfo
                            isLoading={isLoading.now}
                            title="Total de Depósitos"
                            coin="br"
                            value={Number(moneyData?.month?.["1"] || 0)}
                        />
                        <span className="position-absolute end-0 bg-info text-white p-2 rounded-end rounded-bottom-0">
                            Mensal
                        </span>
                    </Card>
                </div>

                <div className="col-md-3">
                    <Card>
                        <CardInfo
                            isLoading={isLoading.now}
                            title="Total de Retiradas"
                            coin="br"
                            value={Number(moneyData?.month?.["54"] || 0)}
                        />
                        <span className="position-absolute end-0 bg-info text-white p-2 rounded-end rounded-bottom-0">
                            Mensal
                        </span>
                    </Card>
                </div>

                <div className="col-md-3">
                    <Card>
                        <CardInfo
                            isLoading={isLoading.now}
                            title="Total de Bonificações"
                            coin="br"
                            value={Number(moneyData?.month?.["58"] || 0)}
                        />
                        <span className="position-absolute end-0 bg-info p-2 rounded-end rounded-bottom-0 text-white">
                            Mensal
                        </span>
                    </Card>
                </div>
            </div>

            <div className="row">
                <div className="col-md-4">
                    <Card className="position-relative">
                        <div className="card-header">
                            <div className="card-title">
                                Resumo geral
                            </div>
                            <span className="position-absolute top-0 end-0 bg-info text-white p-2 rounded-bottom-0 rounded-end">
                                Diário
                            </span>
                        </div>
                        <div className="card-body">
                            <ContentBetween isLoading={isLoading.now} title="Usuários" value={String(usersData?.now || 0)} />
                            <ContentBetween isLoading={isLoading.now} title="Depósitos" value={Number(moneyData?.now?.["1"] || 0)} />
                            <ContentBetween isLoading={isLoading.now} title="Saques" value={Number(moneyData?.now?.["54"] || 0)} />
                            <ContentBetween isLoading={isLoading.now} title="Bonificações" value={Number(moneyData?.now?.["58"] || 0)} />
                            <ContentBetween isLoading={isLoading.now} title="Apostas" value={Number(moneyData?.now?.["56"] || 0)} />
                        </div>
                    </Card>
                </div>

                <div className="col-md-4">
                    <Card className="position-relative">
                        <div className="card-header">
                            <div className="card-title">
                                Resumo geral
                            </div>
                            <span className="position-absolute top-0 end-0 bg-info text-white p-2 rounded-bottom-0 rounded-end">
                                Semanal
                            </span>
                        </div>
                        <div className="card-body">
                            <ContentBetween isLoading={isLoading.week} title="Usuários" value={String(usersData?.week ?? 0)} />
                            <ContentBetween isLoading={isLoading.week} title="Depósitos" value={Number(moneyData?.week?.["1"] || 0)} />
                            <ContentBetween isLoading={isLoading.week} title="Saques" value={Number(moneyData?.week?.["54"] || 0)} />
                            <ContentBetween isLoading={isLoading.week} title="Bonificações" value={Number(moneyData?.week?.["58"] || 0)} />
                            <ContentBetween isLoading={isLoading.week} title="Apostas" value={Number(moneyData?.week?.["56"] || 0)} />
                        </div>
                    </Card>
                </div>

                <div className="col-md-4">
                    <Card className="position-relative">
                        <div className="card-header">
                            <div className="card-title">
                                Resumo geral
                            </div>
                            <span className="position-absolute top-0 end-0 bg-info text-white p-2 rounded-bottom-0 rounded-end">
                                Anual
                            </span>
                        </div>
                        <div className="card-body">
                            <ContentBetween isLoading={isLoading.year} title="Usuários" value={String(usersData?.year ?? 0)} />
                            <ContentBetween isLoading={isLoading.year} title="Depósitos" value={Number(moneyData?.year?.["1"] || 0)} />
                            <ContentBetween isLoading={isLoading.year} title="Saques" value={Number(moneyData?.year?.["54"] || 0)} />
                            <ContentBetween isLoading={isLoading.year} title="Bonificações" value={Number(moneyData?.year?.["58"] || 0)} />
                            <ContentBetween isLoading={isLoading.year} title="Apostas" value={Number(moneyData?.year?.["56"] || 0)} />
                        </div>
                    </Card>
                </div>
            </div>

            <div className="row">
                <div className="col-md-4">
                    <Card className="scroll h-100">
                        <div className="card-header">
                            <div className="card-title">
                                <div className="row align-items-center">
                                    <div className="col-md-9">
                                        <span>Maiores Saldos</span>
                                    </div>
                                    <div className="col-md-3 text-end">
                                        <div className="form-group">
                                            <select className="form-control" onChange={changeBalanceList}>
                                                <option value="">Total</option>
                                                <option value="n">Liberado</option>
                                                <option value="y">Bloqueado</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body text-center">
                            {isBiggestBalancesLoading ? (
                                <div>
                                    <LoadingSkeleton count={6} />
                                </div>
                            ) : usersBiggestBalance.length > 0 ? (
                                usersBiggestBalance.map((user) => (
                                    <div key={user.customer} className="row mb-3 border-bottom border-2 pb-2 align-items-center gap-md-0 gap-2">
                                        <div className="col-md-4 text-center">
                                            <div className="avatar avatar-lg">
                                                <img src={user?.photos ?? Default} alt="" className="avatar-img rounded-circle" />
                                            </div>
                                        </div>
                                        <div className="col-md-4 d-flex flex-column text-md-start text-center gap-2">
                                            <span className="text-truncate">{user.userName}</span>
                                            <span className="text-truncate">{user.email}</span>
                                        </div>
                                        <div className="col-md-4 d-flex flex-column text-center gap-2">
                                            <span className="fw-bold">Saldo</span>
                                            <span>{masks.brlCurrency(user.balance)}</span>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <span>Nenhum registro encontrado.</span>
                            )}
                        </div>
                    </Card>
                </div>

                <div className="col-md-4">
                    <Card className="scroll h-100">
                        <div className="card-header">
                            <div className="card-title">
                                <span>Depósitos Recentes</span>
                            </div>
                        </div>
                        <div className="card-body text-center">
                            {isDepositsLoading ? (
                                <div>
                                    <LoadingSkeleton count={7} />
                                </div>
                            ) : depositsData.length > 0 ? (
                                depositsData.map((deposit) => (
                                    <div key={deposit.id} className="row border-bottom border-2 pb-2 align-items-center gap-md-0 gap-2">
                                        <div className="col-md-2 text-center text-md-start">
                                            <div className="avatar avatar-lg">
                                                <img src={deposit?.avatar ?? Default} alt="" className="avatar-img rounded-circle" />
                                            </div>
                                        </div>
                                        <div className="col-md-4 d-flex flex-column text-md-start text-center gap-2">
                                            <span className="text-truncate">{deposit.customer}</span>
                                            <span className="text-truncate">{deposit.email}</span>
                                            <span>{masks.cpf(deposit.document)}</span>
                                        </div>
                                        <div className="col-md-3 d-flex flex-column text-md-start text-center gap-2">
                                            <span>{masks.brlCurrency(deposit.value)}</span>
                                            <span className="badge badge-info ms-md-0 me-auto">{deposit.method}</span>
                                        </div>
                                        <div className="col-md-3 d-flex flex-column text-md-start text-center gap-2">
                                            <span className="badge badge-success ms-md-0 me-auto">Aprovado</span>
                                            <span>{dayjs(deposit.datePay).format("DD/MM/YYYY [ás] HH:mm:ss")}</span>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div>
                                    <span>Nenhum registro encontrado.</span>

                                </div>
                            )}
                        </div>
                    </Card>
                </div>

                <div className="col-md-4">
                    <Card className="scroll h-100">
                        <div className="card-header">
                            <div className="card-title">
                                <span>Retiradas Recentes</span>
                            </div>
                        </div>
                        <div className="card-body text-center">
                            {isWithdrawsLoading ? (
                                <div>
                                    <LoadingSkeleton count={7} />
                                </div>
                            ) : withdrawsData.length > 0 ? (
                                withdrawsData.map((withdraw) => (
                                    <div key={withdraw.id} className="row border-bottom border-2 pb-2 align-items-center gap-md-0 gap-2">
                                        <div className="col-md-2 text-center text-md-start">
                                            <div className="avatar avatar-lg">
                                                <img src={withdraw?.avatar ?? Default} alt="" className="avatar-img rounded-circle" />
                                            </div>
                                        </div>
                                        <div className="col-md-4 d-flex flex-column text-md-start text-center gap-2">
                                            <span className="text-truncate">{withdraw.customer}</span>
                                            <span className="text-truncate">{withdraw.email}</span>
                                            <span>{masks.cpf(withdraw.document)}</span>
                                        </div>
                                        <div className="col-md-3 d-flex flex-column text-md-start text-center gap-2">
                                            <span>{masks.brlCurrency(withdraw.amount)}</span>
                                            <span className="badge badge-info ms-md-0 me-auto">{withdraw.value}</span>
                                        </div>
                                        <div className="col-md-3 d-flex flex-column text-md-start text-center gap-2">
                                            <span className="badge badge-success ms-md-0 me-auto">Aprovado</span>
                                            <span>{dayjs(withdraw.dateClose).format("DD/MM/YYYY [ás] HH:mm:ss")}</span>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <span>Nenhum registro encontrado.</span>
                            )}
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
}