import { useState } from "react";
import { Card } from '../../components/cards/card/index';
import { HomeCoin } from "./Home-Coin";
import { HomeReal } from "./Home-Real";
import { HomeToken } from "./Home-Token";

export function Home() {
    const [currency, setCurrency] = useState("real");

    const handleCurrencyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setCurrency(event.target.value);
    };

    return (
        <div>
            <Card className="position-relative">
                <div className="card-header">
                    <div className="card-title">
                        <span>Selecione o filtro:</span>
                    </div>
                    <select
                        className="form-select form-select-lg mb-3"
                        aria-label="Default select example"
                        onChange={handleCurrencyChange}
                    >
                        <option value="real">Real</option>
                        <option value="token">Token</option>
                        <option value="coin">Coin</option>
                    </select>
                    <small>
                        Utilize o campo acima para realizar o filtro desejado
                    </small>
                </div>
            </Card>
            {currency === "real" && <HomeReal />}
            {currency === "token" && <HomeToken />}
            {currency === "coin" && <HomeCoin />}
        </div>
    );
}
