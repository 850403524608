import { ConfirmButton } from "@components/buttons/confirm-button";
import { Card } from "@components/cards/card";
import { Pagination } from "@components/pagination";
import { LoadingSkeleton } from "@components/skeleton-loading";
import { CashInserts } from "@hooks/dtos/ICashInsertsDTO";
import { Masks } from "@hooks/functions/Masks/Masks";
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";
import { api } from "@service/api";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";

const status = {
    a: {
        color: "success",
        text: "Aprovado",
    },
    p: {
        color: "warning",
        text: "Pendente"
    },
    n: {
        color: "danger",
        text: "Não Aprovado",
    },
}

const masks = new Masks();
export function RemoveCoin() {
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [cashInserts, setcashInserts] = useState<CashInserts[]>([]);
    const [coinContracts, setCoinContracts] = useState<any[]>([])

    const { actions: { pushToastify } } = ZuToastifyStore()

    async function listInsertCash(page: number = 1) {
        try {
            const response = await api.post(`/manager/coin/inserts/list`, {
                page: page,
                type: "d"
            });

            setcashInserts(response.data.results);
            setTotalPages(response.data.totalPages);
        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro!",
                type: 'error',
                theme: 'dark',
            });
        }
        setIsLoading(false);
    }

    async function removeCash(data: { encryptedId: string, approved: boolean, pin: string | undefined, otp: string | undefined }) {
        try {
            await api.post(`/manager/coin/approved/inserts`, {
                insertId: data.encryptedId,
                approved: data.approved,
                pin: data.pin,
                otpAuth: data.otp,
            });

            pushToastify({
                message: 'Aprovação efetuada com sucesso!',
                type: 'success',
                theme: 'dark'
            });

            listInsertCash();
        } catch (err: any) {
            pushToastify({
                message: err.response?.data || 'Ocorreu um erro',
                type: 'error',
                theme: 'dark'
            });
        }
    }

    async function getCoinContracts() {
        try {
            const result = await api.get("/manager/coin/list?status=a");
            setCoinContracts(result.data);

        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "",
                type: 'error',
                theme: 'dark',
            });
        }
    }

    useEffect(() => {
        listInsertCash();
        getCoinContracts()
    }, [page]);

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <Card className="card-round">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-10">
                                    <h4 className="card-title">Remoções de Token</h4>
                                    <p className="card-category">Lista de todas as Remoções de Token</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <div id="basic-datatables_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                                    <div id="basic-datatables_length" className="dataTables_length">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr role="row">
                                                    <th scope="col" className="fs-6">#</th>
                                                    <th scope="col" className="fs-6">Admin</th>
                                                    <th scope="col" className="fs-6">Usuário</th>
                                                    <th scope="col" className="fs-6">Email</th>
                                                    <th scope="col" className="fs-6">Operação</th>
                                                    <th scope="col" className="fs-6">Token</th>
                                                    <th scope="col" className="fs-6">Valor</th>
                                                    <th scope="col" className="fs-6">Carteira</th>
                                                    <th scope="col" className="fs-6">Status</th>
                                                    <th scope="col" className="fs-6">Dt. Requisição</th>
                                                    <th scope="col" className="fs-6">Dt. Fechamento</th>
                                                    <th scope="col" className="fs-6">Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading ? (
                                                    <tr>
                                                        <td colSpan={12}>
                                                            <LoadingSkeleton />
                                                        </td>
                                                    </tr>
                                                ) : cashInserts.length > 0 ? (cashInserts.map((insert, index) => (
                                                    <tr key={index}>
                                                        <td>{insert.id}</td>
                                                        <td>{insert.admin}</td>
                                                        <td>{insert.user}</td>
                                                        <td>{insert.email}</td>
                                                        <td>{insert.op}</td>
                                                        <td>
                                                            {
                                                                coinContracts.find(contract => contract.id === insert.contract_id)?.name || 'Desconhecido'
                                                            }
                                                        </td>
                                                        <td>{insert.value}</td>
                                                        <td>{(insert.locked == "y") ? "Bloqueada" : "Liberada"}</td>
                                                        <td>
                                                            <span className={`fs-6 badge badge-${status[insert.status].color}`}>
                                                                {status[insert.status].text}
                                                            </span>
                                                        </td>
                                                        <td>{dayjs(insert.dateReq).format("YYYY/MM/DD [ás] HH:mm:ss")}</td>
                                                        <td>{(insert.dateClose) ? dayjs(insert.dateClose).format("YYYY/MM/DD [ás] HH:mm:ss") : "~"}</td>
                                                        <td>
                                                            {insert.status === "p" && (
                                                                <>
                                                                    <ConfirmButton
                                                                        className="btn btn-sm text-success"
                                                                        needConfirm
                                                                        validations="doubleCheck"
                                                                        onConfirm={(e, data) => {
                                                                            if (e === true) {
                                                                                removeCash({
                                                                                    encryptedId: insert.encryptedId,
                                                                                    approved: true,
                                                                                    pin: data?.pin,
                                                                                    otp: data?.otp
                                                                                });
                                                                            }
                                                                        }}
                                                                        textConfirmation="Confirme sua senha e 2FA, para que assim seja feita a aprovação desta inserção de saldo!"
                                                                        title={<FaCheckCircle />}
                                                                    />
                                                                    <ConfirmButton
                                                                        className="btn btn-sm text-danger"
                                                                        needConfirm
                                                                        validations="doubleCheck"
                                                                        onConfirm={(e, data) => {
                                                                            if (e === true) {
                                                                                removeCash({
                                                                                    encryptedId: insert.encryptedId,
                                                                                    approved: false,
                                                                                    pin: data?.pin,
                                                                                    otp: data?.otp
                                                                                });
                                                                            }
                                                                        }}
                                                                        textConfirmation="Confirme sua senha e 2FA, para que assim seja feita a rejeição desta inserção de saldo!"
                                                                        title={<FaCircleXmark />}
                                                                    />
                                                                </>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))) : (
                                                    <tr>
                                                        <td colSpan={12} className="text-center">Nenhum registro encontrado.</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <Pagination page={page} setPage={setPage} totalPages={totalPages} maxPageShow={1} />
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
}