import { Card } from "@components/cards/card";
import { InputSearch } from "@components/forms/input-search";
import { Pagination } from "@components/pagination";
import { LoadingSkeleton } from "@components/skeleton-loading";
import { IWithdrawsPaymentDTO } from "@hooks/dtos/IWithdrawDto";
import { Masks } from "@hooks/functions/Masks/Masks";
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";
import { api } from "@service/api";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const status = {
    s: { color: "success", text: "Enviado" },
    processed: { color: "success", text: "Aprovado" },
    waiting: { color: "warning", text: "Pendente" },
    failed: { color: "danger", text: "Cancelado" },
};

const masks = new Masks();

export function Withdraw() {
    const [isLoading, setIsLoading] = useState(true);
    const [withdraws, setWithdraws] = useState<IWithdrawsPaymentDTO[]>([]);
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState<number | undefined>(undefined);
    const [error, setError] = useState<string | null>(null);
    const { actions: { pushToastify } } = ZuToastifyStore();

    async function fetchWithdraws() {
        setIsLoading(true);
        setError(null);

        try {
            const response = await api.post(`/manager/tokens/transactions/pagination`, {
                page,
                search,
                op: 2
            });
            setWithdraws(response.data?.result || []);
            setTotalPages(response.data?.page);
        } catch (err: any) {
            const errorMessage = err?.response?.data?.message ?? "Ocorreu um erro!";
            setError(errorMessage);
            pushToastify({
                message: errorMessage,
                type: 'error',
                theme: 'dark',
            });
        } finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        fetchWithdraws();
    }, [page, search]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearch(value ? Number(value) : undefined);
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <Card className="card-round">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-10">
                                <h4 className="card-title">Saques</h4>
                                <p className="card-category">Lista de todos os saques</p>
                            </div>
                            <div className="col-md-2">
                                <InputSearch animated label="Buscar" placeholder="" value={search ? search.toString() : ''} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className="dataTables_wrapper container-fluid dt-bootstrap4">
                                <table className="table table-striped">
                                    <thead>
                                        <tr role="row">
                                            <th scope="col" className="fs-6">#</th>
                                            <th scope="col" className="fs-6">Usuário</th>
                                            <th scope="col" className="fs-6">De</th>
                                            <th scope="col" className="fs-6">Para</th>
                                            <th scope="col" className="fs-6">Currency</th>
                                            <th scope="col" className="fs-6">Valor</th>
                                            <th scope="col" className="fs-6">Taxa</th>
                                            <th scope="col" className="fs-6">Status</th>
                                            <th scope="col" className="fs-6">Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ? (
                                            <tr>
                                                <td colSpan={9}>
                                                    <LoadingSkeleton />
                                                </td>
                                            </tr>
                                        ) : withdraws?.length > 0 ? (
                                            withdraws.map((withdraw, index) => (
                                                <tr key={index}>
                                                    <td data-title="#">{withdraw.id}</td>
                                                    <td data-title="User">{withdraw.customer?.id ? withdraw.customer?.id : 'N/A'}</td>
                                                    <td data-title="from">{withdraw.address_from ? withdraw.address_from : 'N/A'}</td>
                                                    <td data-title="to">{withdraw.address_to ? (withdraw.address_to) : 'N/A'}</td>
                                                    <td data-title="currency">{withdraw.currency}</td>
                                                    <td data-title="Value">{masks.dollarCurrency(withdraw.value)}</td>
                                                    <td data-title="Fee">{masks.dollarCurrency(withdraw.value_fee)}</td>
                                                    <td data-title="Status">
                                                        <span className={`fs-6 badge badge-${status[withdraw.status]?.color}`}>
                                                            {status[withdraw.status]?.text}
                                                        </span>
                                                    </td>
                                                    <td data-title="PayDate">
                                                        {withdraw.updatedAt ? dayjs(withdraw.updatedAt).format('DD/MM/YYYY HH:mm:ss') : '~'}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={9} className="text-center">Nenhum registro encontrado.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Pagination page={page} setPage={setPage} totalPages={totalPages} maxPageShow={3} />
                    </div>
                </Card>
            </div>
        </div>
    );
}
