import { Close } from '@components/buttons/close';
import { ConfirmButton } from '@components/buttons/confirm-button';
import { InputToken } from '@components/forms/input-token';
import { yupResolver } from '@hookform/resolvers/yup';
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";
import { api } from '@service/api';
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from 'yup';

interface IForm {
    coinsTypes: number;
    value: number;
    note: string;
    otp?: string;
    pin?: string;
}

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    customerId?: string;
}

export function ModalRemoveCoin({ isOpen, onClose, customerId }: IProps) {
    const { actions: { pushToastify } } = ZuToastifyStore()
    const [animate, setAnimate] = useState(false);
    const [coinContracts, setCoinContracts] = useState<Array<any>>([]);
    const [selectedContract, setSelectedContract] = useState<any>(null);

    const scheme = yup.object().shape({
        value: yup.number()
            .positive('O valor deve ser positivo')
            .min(0, 'O valor deve ser maior que 0')
            .required('Digite o valor a ser depositado.'),

        note: yup.string().required('Digite o motivo de você estar adicionando coins para este usuário!'),

        coinsTypes: yup.number().required('Selecione uma Coin.'),

        otp: yup.string()
            .optional(),

        pin: yup.string()
            .optional(),
    });

    const { register, reset, setValue, watch, handleSubmit, formState: { errors } } = useForm<IForm>({
        resolver: yupResolver(scheme),
        mode: 'onSubmit'
    });
    const value = watch('value');

    function handleClose() {
        setAnimate(false);
        reset();
        setValue("value", 0);
        setTimeout(() => onClose(), 300);
    };

    function handleEscape(evt: KeyboardEvent) {
        if (evt.key === 'Escape' || evt.key === 'Esc' || evt.keyCode === 27) {
            handleClose();
        }
    };

    async function handleRemoveBalance(data: IForm) {
        try {
            await api.post("/manager/customer/coin/manipulation", {
                customerId,
                type: "d",
                amount: data.value,
                note: data.note,
                otpAuth: data.otp,
                pin: data.pin,
                coinsTypes: data.coinsTypes
            });

            pushToastify({
                message: "Sucesso! Ao requisitar inserção!",
                type: 'success',
                theme: 'dark',
            });

            setTimeout(() => handleClose(), 300);
        } catch (err: any) {
            reset();
            setValue("value", 0);

            pushToastify({
                message: err?.response?.data.message ?? "",
                type: 'error',
                theme: 'dark',
            });
        }
    }

    async function getCoinContracts() {
        try {
            const result = await api.get("/manager/coin/list?status=a");
            setCoinContracts(result.data);

        } catch (err: any) {
            reset();
            setValue("value", 0);

            pushToastify({
                message: err?.response?.data.message ?? "",
                type: 'error',
                theme: 'dark',
            });
        }
    }

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => setAnimate(true), 10);
            document.addEventListener('keydown', handleEscape);
            reset();
            setValue("value", 0);
            getCoinContracts();
            return () => {
                document.removeEventListener('keydown', handleEscape);
            };
        } else {
            setAnimate(false);
        }
    }, [isOpen]);

    return (
        <>
            <div className={`modal fade ${animate && isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }} aria-labelledby="staticBackdropLabel" aria-hidden={!isOpen}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="contentModalLabel">Remover Coin</h5>
                            <Close onPress={handleClose} />
                        </div>
                        <div className="modal-body">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                            }}>
                                <div className="row">
                                    <div className="col-md-12 mb-4">
                                        <select
                                            className={`form-select`}
                                            aria-label="Default select example"
                                            defaultValue=""
                                            {...register("coinsTypes")}
                                            onChange={(e) => {
                                                const selectedId = e.target.value
                                                const contract = coinContracts.find(contract => contract.id === parseInt(selectedId))
                                                setSelectedContract(contract)
                                                setValue("coinsTypes", Number(selectedId))
                                            }}>
                                            <option value="" disabled>Selecione a Cripto</option>
                                            {coinContracts.map((contract) => (
                                                <option key={contract.id} value={contract.id}>{contract.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <InputToken
                                            animated
                                            label="Valor"
                                            placeholder={`0,${selectedContract ? '0'.repeat(selectedContract.decimals) : ''}`}
                                            floats={selectedContract ? selectedContract.decimals : 0}
                                            value={value}
                                            onChangeTextOut={(v: number, valueFormat?: string) => {
                                                setValue("value", v);
                                            }}
                                            min={0}
                                            errorMessage={String(errors?.value?.message || "")} />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <div className="form-floating">
                                            <textarea {...register('note')} className={`form-control ${errors?.note?.message ? 'is-invalid' : ''}`}></textarea>
                                            <label>Nota</label>
                                        </div>

                                        {errors?.note?.message &&
                                            <div className="text-danger">
                                                {errors?.note?.message}
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="mt-2 col-md-12 text-end">
                                        <ConfirmButton
                                            validations="doubleCheck"
                                            textConfirmation="Por favor, confirme sua Senha, OTP e o envio do Formulário para a requisição ser feita!"
                                            needConfirm={true}
                                            onConfirm={(e, data) => {
                                                if (e == true) {
                                                    setValue("otp", data?.otp);
                                                    setValue("pin", data?.pin);
                                                    handleSubmit(handleRemoveBalance)();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal-backdrop fade ${animate && isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }}></div>
        </>
    );
}