/* eslint-disable jsx-a11y/anchor-is-valid */
import admin from '@assets/img/admin.jpg';
import { useAuthContext } from '@hooks/contexts/auth-context';

import { useSidebarCollapse } from '@hooks/contexts/SidebarCollapseContext';
import { useTopbarCollapse } from '@hooks/contexts/TopbarCollapseContext';
import { IManagerDTO } from '@hooks/dtos/user-dto';
import { ZuAuthStore } from '@hooks/stores/zustand-auth-store';
import { useEffect, useState } from 'react';
import { FaArrowRightFromBracket } from 'react-icons/fa6';

export default function NavbarTop() {
    const { toggleSidebar } = useSidebarCollapse();
    const { toggleTopbar } = useTopbarCollapse();
    const { manager } = ZuAuthStore();
    const { signOut } = useAuthContext();
    const [userData, setUserData] = useState<IManagerDTO | null>(null);


    useEffect(() => {
        const savedData = localStorage.getItem('@panda:manager');
        if (savedData) {
            const parsedData: IManagerDTO = JSON.parse(savedData);
            setUserData(parsedData);
        }
    }, []);

    return (
        <>
            <div className="main-header">
                <div className="main-header-logo">
                    <div className="logo-header" data-background-color="dark">
                        <div className="nav-toggle">
                            <button className="btn btn-toggle sidenav-toggler " onClick={toggleSidebar}>
                                <i className="gg-menu-left m-2"></i>
                            </button>
                        </div>
                        <button className="topbar-toggler more p-3" onClick={toggleTopbar}>
                            <i className="gg-more-vertical-alt"></i>
                        </button>
                    </div>
                </div>

                <nav className="navbar navbar-header navbar-header-transparent navbar-expand-lg border-bottom">
                    <div className="container-fluid">
                        <ul className="navbar-nav topbar-nav ms-md-auto align-items-center">
                            {/* Notifications */}
                            {/* <li className="nav-item topbar-icon dropdown hidden-caret">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    id="notifDropdown"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <i className="bi bi-bell-fill"></i>
                                    <span className="notification">1</span>
                                </a>
                                <ul className="dropdown-menu notif-box animated fadeIn" aria-labelledby="notifDropdown">
                                    <li>
                                        <div className="dropdown-title">
                                            You have 1 new notification
                                        </div>
                                    </li>
                                    <li>
                                        <div className="notif-scroll scrollbar-outer">
                                            <div className="notif-center">
                                                <a href="#">
                                                    <div className="notif-icon notif-primary">
                                                        <i className="fa fa-user-plus"></i>
                                                    </div>
                                                    <div className="notif-content">
                                                        <span className="block"> New user registered </span>
                                                        <span className="time">5 minutes ago</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <a className="see-all" href="javascript:void(0);">
                                            See all notifications
                                            <i className="fa fa-angle-right"></i>
                                        </a>
                                    </li>
                                </ul>
                            </li> */}

                            {/* Profile */}
                            <li className="nav-item topbar-user dropdown hidden-caret">
                                <a className="dropdown-toggle profile-pic" data-bs-toggle="dropdown" href="#" aria-expanded="false">
                                    <div className="avatar-sm">
                                        <img src={admin} alt="..." className="avatar-img rounded-circle" />
                                    </div>
                                    <span className="profile-username">
                                        <span className="fw-bold text-capitalize">{manager?.user?.name ?? 'Admin'}</span>
                                    </span>
                                </a>
                                <ul className="dropdown-menu dropdown-user animated fadeIn">
                                    <div className="dropdown-user-scroll scrollbar-outer">
                                        <li>
                                            <div className="user-box">
                                                <div className="avatar-lg">
                                                    <img src={admin} alt="image profile" className="avatar-img rounded-circle" />
                                                </div>
                                                <div className="u-text">
                                                    <h4 className="mb-2">{manager?.user?.name ?? 'Admin'}</h4>
                                                    <a href="/profile" className="btn btn-xs btn-secondary btn-sm">
                                                        Visualizar Perfil
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="dropdown-divider"></div>
                                            <a className="dropdown-item text-end text-danger" onClick={signOut} href="#">
                                                Sair
                                                <FaArrowRightFromBracket className="ms-2" />
                                            </a>
                                        </li>
                                    </div>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </>
    )
}