import React, { useEffect, useState } from 'react'

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string
    placeholder?: string
    animated?: boolean
    register?: any
    prefix?: string
    sufix?: string
    decimal?: string
    floats?: number
    thousands?: string
    errorMessage?: string
    className?: string
    value?: number
    showButtons?: boolean // Novo prop para mostrar ou esconder os botões
    showButtonsDisabled?: boolean
    labelPosition?: 'top' | 'side' // Novo prop para escolher a posição do label
    onChangeTextOut?: (v: number, valueFormat?: string) => void
}

export function InputToken({
    label,
    prefix = '',
    sufix = '',
    decimal = ',',
    floats = 9,
    thousands = '.',
    className,
    placeholder,
    animated,
    register,
    errorMessage,
    value,
    max,
    min = 0,
    showButtons = false,
    showButtonsDisabled = false,
    labelPosition = 'top',
    onChangeTextOut,
    ...rest
}: IProps) {
    const [input, setInput] = useState('')
    const [numValue, setNumValue] = useState(value || 0)

    const handleIncrease = () => {
        const newValue = Number(numValue) + 1
        if (max !== undefined && newValue > Number(max)) return
        setNumValue(newValue)
        if (onChangeTextOut) {
            onChangeTextOut(newValue, applyMask(String(newValue)))
        }
    }

    const handleDecrease = () => {
        const newValue = Number(numValue) - 1
        if (newValue < Number(min)) return
        setNumValue(newValue)
        if (onChangeTextOut) {
            onChangeTextOut(newValue, applyMask(String(newValue)))
        }
    }

    function convertStringToNumber(value: string): string {
        value = value.replace(/[^0-9.,]/g, '')

        if (value.startsWith(`0${decimal}`)) {
            value = value.replace(`0${decimal}`, '')
        }

        const array = value.split('').reverse()
        if (array[floats - 1] == decimal) {
            const item = array.splice(floats - 1, 1)[0]
            array.splice(floats, 0, item)
        }

        if (array.length < 1) {
            setNumValue(0)
            return '0'
        }
        const replace = []
        if (array.length <= floats) {
            array.push(...''.padEnd(floats + 1 - array.length, '0').split(''))
        }

        for (let index = 0; index < array.length; index++) {
            const element = array[index]
            if (element.match(/[0-9]/g)) {
                replace.unshift(element)
            }
            if (index > 0 && index === floats - 1) {
                replace.unshift('.')
            }
        }

        if (replace[0] === '.') {
            replace.push('0')
        }

        const numericValue = parseFloat(replace.join('')).toFixed(floats)

        if (max && Number(numericValue) > Number(max)) {
            setNumValue(Number(max))
            return String(max)
        }

        if (min && Number(numericValue) < Number(min)) {
            setNumValue(Number(min))
            return String(min)
        }

        setNumValue(Number(numericValue))
        return numericValue
    }

    function applyMask(value: string): string {
        if (!value || Number(value) === 0) {
            setInput('')
            if (onChangeTextOut) {
                onChangeTextOut(0, '')
            }
            return ''
        }

        const [int, decimals] = parseFloat(value).toFixed(floats).split('.')
        const intRev = int.split('').reverse()
        const replace = []

        let addThousands = 0
        for (let index = 0; index < intRev.length; index++) {
            const element = intRev[index]
            replace.unshift(element)
            if (index > 0 && index + (1 % 3) === 0 && index + 1 < intRev.length) {
                replace.unshift(thousands || '.')
            }
            addThousands++

            if (addThousands === 3 && index + 1 < intRev.length) {
                replace.unshift(thousands || '')
                addThousands = 0
            }
        }

        const formatValue = `${prefix || ''} ${replace.join('')}${decimal}${!decimals ? ''.padEnd(floats, '0') : decimals.padEnd(floats, '0')}${sufix || ''}`

        setInput(formatValue)
        if (onChangeTextOut) {
            onChangeTextOut(Number(value), formatValue)
        }
        return formatValue
    }

    useEffect(() => {
        if (typeof value === 'number' && value !== numValue) {
            setNumValue(value)
        }
    }, [value])

    useEffect(() => {
        applyMask(String(numValue))
    }, [numValue])

    return (
        <>
            <div
                className={labelPosition === 'side' ? 'd-flex align-items-center' : ''}
            >
                {label && (
                    <label
                        className={`form-label ${labelPosition === 'side' ? 'w-50 me-3 pt-3' : ''}`}
                    >
                        {label}
                    </label>
                )}
                <div className="flex-grow-1 mt-2">
                    <div className="input-group">
                        {showButtons && (
                            <button
                                type="button"
                                className={`btn btn-outline-secondary ${showButtonsDisabled ? `disabled` : ``}`}
                                onClick={handleDecrease}
                            >
                                -
                            </button>
                        )}
                        <input
                            className={`form-control ${className} ${errorMessage ? 'is-invalid' : ''}`}
                            type="tel"
                            {...register}
                            {...rest}
                            placeholder={
                                placeholder ||
                                `${prefix || ''} 0${decimal ? `${decimal}000000000` : ``}`
                            }
                            onChange={(e) => convertStringToNumber(e.target.value)}
                            value={input}
                        />
                        {showButtons && (
                            <button
                                type="button"
                                className={`btn btn-outline-secondary ${showButtonsDisabled ? `disabled` : ``}`}
                                onClick={handleIncrease}
                            >
                                +
                            </button>
                        )}
                    </div>
                    {errorMessage && (
                        <div className="invalid-feedback">{errorMessage}</div>
                    )}
                </div>
            </div>
        </>
    )
}
