import { LoadingSkeleton } from "@components/skeleton-loading";
import { Masks } from "@hooks/functions/Masks/Masks";

interface IProps {
  title: string;
  subtitle?: string;
  coin?: string; // Propriedade coin para indicar a moeda
  value: string | number; // Valor pode ser string ou number
  isLoading?: boolean;
  className?: string;
}

const masks = new Masks();

export function CardInfo({ title, subtitle, value, isLoading, coin, className }: IProps) {
  const formattedValue = () => {
  if (typeof value === "number") {
    switch (coin) {
      case "br":
        return <>{masks.brlCurrency(value)}</>;
      case "coin":
        return (
          <>
            {masks.cryptoCurrency(value)}{" "}
            <small className="text-muted">PNDC</small>
          </>
        );
      case "dollar":
        return <>{masks.cryptoCurrency(value)}{" "}
            <small className="text-muted">USDT</small>
          </>
      default:
        return value;
    }
  }
  return value;
};

  return (
    <>
      <div className="card-header">
        <h5 className="card-title">
          <span>{title}</span>
        </h5>
        {subtitle && (
          <div className="card-category p-0 m-0">
            <small>{subtitle}</small>
          </div>
        )}
      </div>
      <div className="card-body">
        {isLoading ? (
          <div className="w-100">
            <LoadingSkeleton count={2} />
          </div>
        ) : (
          <h4 className="fw-bolder">{formattedValue()}</h4>
        )}
      </div>
      </>
  );
}