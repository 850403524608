/* eslint-disable no-unused-vars */

import { IManagerDTO } from '@hooks/dtos/user-dto';
import { ZuAuthStore } from '@hooks/stores/zustand-auth-store';
import { ZuToastifyStore } from '@hooks/stores/zustand-toastify-store';
import { api } from '@service/api';
import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
} from 'react';

interface ISignInProps {
    userName: string;
    password: string;
}

interface IRegisterProps {
    gender?: number,
    userName: string,
    name: string,
    password: string,
    passwordConfirm: string,
    email: string,
    emailConfirm: string,
    cellPhone: string,
    termsCheck: string,
    birth?: string,
    language: string,
    document?: string,
    referral?: number
}

interface AuthContextData {
    signIn(data: ISignInProps): Promise<void>
    createCustomer(data: IRegisterProps): Promise<void>
    signOut: () => void
    getUserProfile: () => Promise<void>
    manager: IManagerDTO | undefined
}

interface AuthProviderProps {
    children: ReactNode
}

export const AuthContext = createContext({} as AuthContextData)

export function AuthProvider({ children }: AuthProviderProps) {

    const {
        manager,
        actions: { setManager, checkIfHaveSession },
    } = ZuAuthStore()

    const {
        actions: { pushToastify }
    } = ZuToastifyStore()

    async function signIn(data: ISignInProps) {
        try {
            const response = await api.post('/manager/sign-in', {
                userName: data.userName,
                password: data.password,
            })

            const { user } = response.data;
            setManager({ user })

            pushToastify({
                message: 'Login realizado com sucesso!',
                type: 'success',
                theme: 'dark',
            });
        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro ao realizar o login!",
                type: 'error',
                theme: "dark",
            });
        }
    }

    async function createCustomer(data: IRegisterProps) {

        try {
            const response = await api.post('/customer/create', {
                customer: {
                    gender: data.gender,
                    userName: data.email,
                    name: data.name,
                    password: data.password,
                    passwordConfirm: data.passwordConfirm,
                    email: data.email,
                    emailConfirm: data.email,
                    cellPhone: data.cellPhone,
                    termsCheck: data.termsCheck ? 'y' : 'n',
                    birth: data.birth,
                    language: "pt-br",
                    referral: data.referral,
                }
            })
            // Logando o usuário após o seu cadastro
            signIn({ userName: data.email, password: data.password });

            pushToastify({
                message: 'Login realizado com sucesso!',
                type: 'success',
                theme: "dark",
            });
        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro ao realizar o login!",
                type: 'error',
                theme: "dark",
            });

        }
    }

    async function getUserProfile() { }

    async function signOut() {
        setManager(undefined);
        localStorage.removeItem('@panda:manager');
    }

    useEffect(() => {
        const responseInterceptor = api.interceptors.response.use(
            async (response) => {
                return response
            },
            async (error) => {
                const errorCode = String(error?.response?.data?.errorCode || '')
                if (errorCode.match('CSRFX')) {
                    signOut()
                }
                return Promise.reject(error)
            },
        )

        return () => {
            api.interceptors.response.eject(responseInterceptor)
        }
    }, [])


    useEffect(() => {
        checkIfHaveSession()
    }, []);

    return (
        <AuthContext.Provider
            value={{
                signIn,
                signOut,
                createCustomer,
                getUserProfile,
                manager,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => useContext(AuthContext)
