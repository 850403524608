/* eslint-disable no-useless-escape */

export class Masks {
  name(value: string): string {
    const data = value.replace(/[\@/$%!#^&*\(\)]/g, '')
    return data
  }

  postalCode(value: string): string {
    const data = value.replace(/[^0-9]/g, '').slice(0, 8)
    if (data.length <= 5) {
      return data
    }
    return `${data.slice(0, 5)}-${data.slice(5, 8)}`
  }

  date(value: string): string {
    let data = value.replace(/[^0-9]/g, '').slice(0, 8)
    const days = Number(data.slice(0, 2))
    if (days > 31) {
      data = '01' + data.substring(2)
    }
    if (data.length >= 4 && Number(data.slice(2, 4)) > 12) {
      const array = data.split('')
      array[2] = '0'
      array[3] = '1'
      data = array.join('')
    }

    if (data.length >= 4) {
      const checkDays = new Date(
        Number(data.slice(4, 8)),
        Number(data.slice(2, 4)),
        0,
      ).getDate()
      if (checkDays < days) {
        data = `${checkDays}` + data.substring(2)
      }
    }

    const regex = /^([0-9]{2})([0-9]{0,2})([0-9]{0,4})$/
    const result = data.replace(
      regex,
      `$1${data.length <= 2 ? '' : '/'}$2${data.length <= 4 ? '' : '/'}$3`,
    )

    return result
  }

  cpf(value: string): string {
    if (!value) {
      return value
    }

    const str = String(value)
      .replace(/[^0-9]/g, '')
      .slice(0, 11)

    let result = str

    const regex = /^([0-9]{3})([0-9]{0,3})([0-9]{0,3})([0-9]{0,2})$/
    const regex2 = /^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})$/

    if (str.length <= 10) {
      const l = str.length
      result = str.replace(
        regex,
        `$1${l <= 3 ? '' : '.'}$2${l <= 6 ? '' : '.'}$3${l <= 9 ? '' : '-'}$4`,
      )
    } else {
      result = str.replace(regex2, '$1.$2.$3-$4')
    }

    return result
  }

  cnpj(value: string): string {
    if (!value) {
      return value
    }

    const str = String(value)
      .replace(/[^0-9]/g, '')
      .slice(0, 14)

    let result = str

    const regex = /^([0-9]{2})([0-9]{0,3})([0-9]{0,3})([0-9]{0,4})([0-9]{0,2})$/
    const regex2 = /^([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})$/

    if (str.length <= 10) {
      const l = str.length

      result = str.replace(
        regex,
        `$1${l <= 2 ? '' : '.'}$2${l <= 5 ? '' : '.'}$3${l <= 9 ? '' : '/'}$4${l <= 12 ? '' : '-'
        }$5`,
      )
    } else {
      result = str.replace(regex2, '$1.$2.$3/$4-$5')
    }

    return result
  }

  phone(value: string): string {
    if (!value) {
      return value
    }

    const str = String(value)
      .replace(/[^0-9]/g, '')
      .slice(0, 11)
    let result = str

    const regex = /^([0-9]{2})([0-9]{0,4})([0-9]{0,4})$/

    const regex2 = /^([0-9]{2})([0-9]{5})([0-9]{4})$/

    if (str.length <= 10) {
      result = str.replace(
        regex,

        `$1${str.length <= 2 ? '' : ' '}$2${str.length <= 6 ? '' : '-'}$3`,
      )
    } else {
      result = str.replace(regex2, '$1 $2-$3')
    }

    return result
  }

  formatNumber(
    number = 0,
    decimal = ',',
    thousands = '.',
    symbol = '',
    decimalNumbers = 2,
  ): string {
    const sign = number < 0 ? '-' : ''
    number = Math.abs(number)
    const numberString = number.toString().replace('-', '')

    if (!numberString.match('.')) {
      return `${sign}${numberString}.00`
    }

    let numberSplit = numberString.split('.')

    if (numberSplit.length != 2) {
      numberSplit = number.toFixed(decimalNumbers).split('.')

      if (numberSplit.length != 2) {
        return 'Huston we have a problem!'
      }
    }

    const integer = numberSplit[0].replace(
      /(.)(?=(\d{3})+$)/g,
      `$1${thousands}`,
    )
    let decimalValue = numberSplit[1].substring(0, decimalNumbers)

    decimalValue = decimalValue.padEnd(decimalNumbers, '0')

    return `${symbol} ${sign}${integer}${decimal}${decimalValue}`
  }

  brlCurrency(number = 0): string {
    const decimal = ','
    const thousands = '.'
    const symbol = 'R$'
    const decimalNumbers = 2

    const sign = number < 0 ? '-' : ''
    number = Math.abs(number)
    const numberString = number.toString().replace('-', '')

    if (!numberString.match('.')) {
      return `${sign}${numberString}.00`
    }

    let numberSplit = numberString.split('.')

    if (numberSplit.length != 2) {
      numberSplit = number.toFixed(decimalNumbers).split('.')

      if (numberSplit.length != 2) {
        return 'Huston we have a problem!'
      }
    }

    const integer = numberSplit[0].replace(
      /(.)(?=(\d{3})+$)/g,
      `$1${thousands}`,
    )
    let decimalValue = numberSplit[1].substring(0, decimalNumbers)

    decimalValue = decimalValue.padEnd(decimalNumbers, '0')

    return `${symbol} ${sign}${integer}${decimal}${decimalValue}`
  }
  coinCurrency(number = 0): string {
    const decimal = ','
    const thousands = '.'
    const symbol = ''
    const decimalNumbers = 3

    const sign = number < 0 ? '-' : ''
    number = Math.abs(number)
    const numberString = number.toString().replace('-', '')

    if (!numberString.match('.')) {
      return `${sign}${numberString}.00`
    }

    let numberSplit = numberString.split('.')

    if (numberSplit.length != 2) {
      numberSplit = number.toFixed(decimalNumbers).split('.')

      if (numberSplit.length != 2) {
        return 'Huston we have a problem!'
      }
    }

    const integer = numberSplit[0].replace(
      /(.)(?=(\d{3})+$)/g,
      `$1${thousands}`,
    )
    let decimalValue = numberSplit[1].substring(0, decimalNumbers)

    decimalValue = decimalValue.padEnd(decimalNumbers, '0')

    return `${symbol} ${sign}${integer}${decimal}${decimalValue}`
  }
  dollarCurrency(number = 0): string {
    const decimal = ','
    const thousands = '.'
    const symbol = '$'
    const decimalNumbers = 2

    const sign = number < 0 ? '-' : ''
    number = Math.abs(number)
    const numberString = number.toString().replace('-', '')

    if (!numberString.match('.')) {
      return `${sign}${numberString}.00`
    }

    let numberSplit = numberString.split('.')

    if (numberSplit.length != 2) {
      numberSplit = number.toFixed(decimalNumbers).split('.')

      if (numberSplit.length != 2) {
        return 'Huston we have a problem!'
      }
    }

    const integer = numberSplit[0].replace(
      /(.)(?=(\d{3})+$)/g,
      `$1${thousands}`,
    )
    let decimalValue = numberSplit[1].substring(0, decimalNumbers)

    decimalValue = decimalValue.padEnd(decimalNumbers, '0')

    return `${symbol} ${sign}${integer}${decimal}${decimalValue}`
  }

  bankAccount(input: string, maxDigits = 2): string {
    const cleanedInput = input.replace(/[^\d-]/g, '')
    const hifenSplit = cleanedInput.split('-').slice(0, 2)

    let formattedInput = hifenSplit.join('-').replace(/-+/g, '-')

    if (hifenSplit.length == 2 && hifenSplit[1].length > maxDigits) {
      formattedInput = `${hifenSplit[0]}-${hifenSplit[1].substring(0, maxDigits)}`
    }

    return formattedInput
  }

  cryptoCurrency(number = 0, decimalNumbers = 4, symbol = ''): string {
    const decimal = '.'
    const thousands = ''

    const sign = number < 0 ? '-' : ''
    number = Math.abs(number)
    const numberString = number.toString().replace('-', '')

    if (!numberString.match('.')) {
      return `${sign}${numberString}.00`
    }

    let numberSplit = numberString.split('.')

    if (numberSplit.length !== 2) {
      numberSplit = number.toFixed(decimalNumbers).split('.')

      if (numberSplit.length === 1) {
        numberSplit.push('00')
      }

      if (numberSplit.length !== 2) {
        return 'Huston we have a problem!'
      }
    }

    const integer = numberSplit[0].replace(
      /(.)(?=(\d{3})+$)/g,
      `$1${thousands}`,
    )
    let decimalValue = numberSplit[1].substring(0, decimalNumbers)

    decimalValue = decimalValue.padEnd(decimalNumbers, '0')

    return `${symbol} ${sign}${integer}${decimal}${decimalValue}`
  }
}
