import { Card } from "@components/cards/card";
import { CardInfoCoin } from "@components/cards/card-info-coin";
import { CardInfoDollar } from "@components/cards/card-info-dollar";
import { Pagination } from "@components/pagination";
import { LoadingSkeleton } from "@components/skeleton-loading";
import { IGetFinancialSummaryDTO, ITokenTransaction } from "@hooks/dtos/IFinancialDto";
import { Masks } from "@hooks/functions/Masks/Masks";
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";
import { api } from "@service/api";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { FaBroom } from "react-icons/fa6";

interface IParams {
  page?: number;
  startAt?: string;
  endDate?: string;
}

const masks = new Masks();
export function GeneralTokens() {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [resultTime, setResultTime] = useState("Todo Período");
  const [startAt, setStartAt] = useState("");
  const [endDate, setEndDate] = useState("");

  const [financialData, setFinancialData] = useState<IGetFinancialSummaryDTO>({} as IGetFinancialSummaryDTO);
  const [transactions, setTransactions] = useState<ITokenTransaction[]>([]);

  const { actions: { pushToastify } } = ZuToastifyStore()

  const [isLoading, setIsLoading] = useState({
    card: true,
    table: true
  });

  const formatAddress = (address: string): string => {
    if (address) return `${address.slice(0, 5)}...${address.slice(-4)}`

    return ''
  }

  async function getFinancialSummary({ startAt, endDate }: IParams) {
    try {
      const result = await api.post("/manager/financial/summary/tokens", {
        startAt: startAt || "",
        endAt: endDate || ""
      });
      setFinancialData(result.data);
      setIsLoading((prev) => ({ ...prev, 'card': false }));
    } catch (err: any) {
      setIsLoading((prev) => ({ ...prev, 'card': false }));
      pushToastify({
        message: err?.response?.data.message ?? "Ocorreu um erro!",
        type: 'error',
        theme: 'dark',
      });
    }
  }

  async function getHistoricalTransaction({ page, startAt, endDate }: IParams) {
    try {
      const result = await api.post("/manager/transactions/list/tokens", {
        page: page || 1,
        startAt: startAt || "",
        endDate: endDate || ""
      });
      console.log(result.data.result)
      setTransactions(result.data.result);
      setTotalPages(result.data.pages);

      setIsLoading((prev) => ({ ...prev, 'table': false }));
    } catch (err: any) {
      setIsLoading((prev) => ({ ...prev, 'table': false }));
      pushToastify({
        message: err?.response?.data.message ?? "Ocorreu um erro!",
        type: 'error',
        theme: 'dark',
      });
    }
  }

  async function cleanFilters() {
    setStartAt("");
    setEndDate("");
    handleSubmit("", "");
  }

  function handleSubmit(_startAt: string, _endDate: string) {
    setIsLoading({ card: true, table: true });
    setResultTime((_startAt.length + _endDate.length) > 0 ? `Período Selecionado` : `Todo Período`);

    const data = {
      page: 1,
      startAt: _startAt,
      endDate: _endDate,
    }

    getFinancialSummary(data);
    if (page == 1) {
      getHistoricalTransaction(data);
    } else {
      setPage(1);
    }
  }

  useEffect(() => {
    const data = {
      page: page,
      startAt: startAt,
      endDate: endDate,
    }

    getHistoricalTransaction(data);
  }, [page]);

  return (
    <>
      {/* Filters */}
      <div className="row">
        <div className="col-md-12">
          <Card>
            <div className="card-header">
              <div className="card-title">Filtrar Registros</div>
              <div className="card-category">Utilize o filtro para trazer um período específico.</div>
            </div>
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-md-5">
                  <div className="form-group">
                    <label htmlFor="startAt">Data Inicial</label>
                    <input type="date" className="form-control" onChange={(e) => setStartAt(e.target.value)} id="startAt" value={startAt} />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <label htmlFor="endDate">Data Final</label>
                    <input type="date" className="form-control" onChange={(e) => setEndDate(e.target.value)} id="endDate" value={endDate} />
                  </div>
                </div>

                {/* Buttons */}
                <div className="col-md-2">
                  <div className="d-flex justify-content-around">
                    <div className="form-group">
                      <label htmlFor=""> </label>
                      <button className="btn btn-sm btn-success form-control px-5" onClick={() => handleSubmit(startAt, endDate)}>Filtrar</button>
                    </div>
                    <div className="form-group">
                      <label htmlFor=""> </label>
                      <button className="btn btn-sm btn-warning form-control" onClick={cleanFilters} title="Limpar todos os filtros."><FaBroom /></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <div className="row mb-4 gap-3 gap-md-0">
        {/* Bets Amount */}
        <div className="col-md-4">
          <Card className="h-100">
            <CardInfoDollar
              className="d-flex justify-content-center align-items-center"
              isLoading={isLoading.card}
              title="Lucro da Plataforma"
              subtitle="Valor lucrado com a captação de recursos e subtração das despesas"
              value={Number(financialData?.profit?.platformProfit?.balance) ?? 0}
            />
          </Card>
        </div>

        {/* Bets Amount */}
        <div className="col-md-4">
          <Card className="h-100">
            <CardInfoDollar
              className="d-flex justify-content-center align-items-center"
              isLoading={isLoading.card}
              title="Repasse ao Provedor"
              subtitle="Total de repasse ao Provedor"
              value={Number(financialData?.profit?.transferToProvider?.balance ?? 0)}
            />
          </Card>
        </div>

        {/* Bets Amount */}
        <div className="col-md-4">
          <Card className="h-100">
            <CardInfoCoin
              className="d-flex justify-content-center align-items-center"
              isLoading={isLoading.card}
              title="Total Apostado"
              subtitle="Todo valor de entradas em apostas"
              value={Number(financialData?.profit?.totalBet?.amount ?? 0)}
            />
          </Card>
        </div>
      </div>

      {/* Main Profit */}
      <div className="row">
        <div className="col-md-12">
          <Card>
            <div className="card-header">
              <div className="card-title">Resumo Financeiro</div>
              <div className="card-category">Rendimentos e pendências resumidas.</div>
            </div>
            <div className="card-body">
              <div className="row">
                {/* Business Profit */}
                <div className="col-md-4">
                  <div className="card-header text-center">
                    <div className="fs-5">
                      <div className="">Balance da Empresa</div>
                    </div>
                    <div className="text-muted fs-6 p-0 m-0">
                      <small>Resultado do balance de Caixa</small>
                    </div>
                  </div>
                  <div className="card-body">
                    {isLoading.card ? (
                      <div className="w-100">
                        <LoadingSkeleton count={2} />
                      </div>
                    ) : (
                      <div className="row d-flex justify-content-between">
                        <div className="col-md-12 col-xxl-6 text-center">
                          <span className="fs-4 fw-bold">{masks.dollarCurrency(financialData?.companyBalance?.gross?.balance ?? 0)}</span>
                          <br />
                          <span>Bruto</span>
                        </div>
                        <div className="col-md-12 col-xxl-6 text-center">
                          <span className="fs-4 fw-bold">
                            {masks.dollarCurrency(Number(financialData?.companyBalance?.total?.balance ?? 0))}
                          </span>
                          <br />
                          <span>Líquido</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Transactions Internal & External */}
                <div className="col-md-4">
                  <div className="card-header text-center">
                    <div className="fs-5">
                      <div className="">Movimentações</div>
                    </div>
                    <div className="text-muted fs-6 p-0 m-0">
                      <small>Entradas e Saídas de Saldo</small>
                    </div>
                  </div>
                  <div className="card-body">
                    {isLoading.card ? (
                      <div className="w-100">
                        <LoadingSkeleton count={2} />
                      </div>
                    ) : (
                      <div className="row d-flex justify-content-between">
                        <div className="col-md-12 col-xxl-6 text-center">
                          <span className="fs-4 fw-bold">{masks.dollarCurrency(Number(financialData?.movements?.depositMovements?.value ?? 0))}</span>
                          <br />
                          <span>Depósitos</span>
                        </div>
                        {/* <div className="col-md-12 col-xxl-6 text-center">
                            <span className="fs-4 fw-bold">{masks.dollarCurrency(Number(financialData?.movements?.depositMovements?.value ?? 0))}</span>
                          <br />
                          <span>Swap</span>
                        </div> */}
                        <div className="col-md-12 col-xxl-6 text-center">
                          <span className="fs-4 fw-bold">
                            {masks.dollarCurrency(Number(financialData?.movements?.withdrawMovements?.value ?? 0))}
                          </span>
                          <br />
                          <span>Saques</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Customer's Balances */}
                <div className="col-md-4">
                  <div className="card-header text-center">
                    <div className="fs-5">
                      <div className="">Caixa dos Usuários</div>
                    </div>
                    <div className="text-muted fs-6 p-0 m-0">
                      <small>Dinheiro em Caixa dos Usuários</small>
                    </div>
                  </div>
                  <div className="card-body">
                    {isLoading.card ? (
                      <div className="w-100">
                        <LoadingSkeleton count={2} />
                      </div>
                    ) : (
                      <div className="row d-flex justify-content-between">
                        <div className="col-md-12 col-xxl-6 text-center">
                          <span className="fs-4 fw-bold">{masks.dollarCurrency(Number(financialData?.usersFinancial?.userFinancialUsdt?.balance ?? 0))}</span>
                          <br />
                          <span>USDT</span>
                        </div>
                        <div className="col-md-12 col-xxl-6 text-center">
                          <span className="fs-4 fw-bold">
                            {masks.coinCurrency(Number(financialData?.usersFinancial?.userFinancialCoin?.balance ?? 0))}
                          </span>
                          <br />
                          <span>Coin</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <span className="position-absolute end-0 bg-info text-white p-2 rounded-end rounded-bottom-0">
              {resultTime}
            </span>
          </Card>
        </div>
      </div>

      {/* All Platform Profits */}
      <div className="row gap-3 gap-md-0">
        {/* Work Fees */}
        <div className="col-md-4">
          <Card className="h-100">
            <div className="card-header">
              <div className="card-title d-flex justify-content-between">
                <div className="">Arrecadação Principal</div>
                <div className="fs-5"><FaInfoCircle title="Trata-se dos valores arrecadados nas rodadas dos jogos." /></div>
              </div>
              <div className="card-category p-0 m-0">
                <small>Valores arrecadados nas apostas</small>
              </div>
            </div>
            <div className="card-body d-flex justify-content-center align-items-center">
              {isLoading.card ? (
                <div className="w-100">
                  <LoadingSkeleton count={2} />
                </div>
              ) : (
                <h4 className="fw-bolder">
                  {masks.coinCurrency(Number(financialData?.profitBets?.profit ?? 0))}
                </h4>
              )}
            </div>
          </Card>
        </div>

        {/* Internal Fees */}
        <div className="col-md-4">
          <Card className="h-100">
            <div className="card-header">
              <div className="card-title">Taxa de Saque</div>
              <div className="card-category">Recolhimento da plataforma</div>
            </div>
            <div className="card-body">
              {isLoading.card ? (
                <div className="w-100">
                  <LoadingSkeleton count={2} />
                </div>
              ) : (
                <div className="row d-flex justify-content-between align-items-center">
                  <div className="col-md-12 col-xxl-6 text-center">
                    <span className="fs-4 fw-bold">
                      {masks.dollarCurrency(Number(financialData?.withdrawalFee.total?.balance ?? 0))}
                    </span>
                    <br />
                    <span>Total</span>
                  </div>
                  <div className="col-md-12 col-xxl-6 text-center">
                    <span className="fs-4 fw-bold">
                      {masks.dollarCurrency(Number(financialData?.withdrawalFee?.lucro?.balance ?? 0))}
                    </span>
                    <br />
                    <span>Lucro</span>
                  </div>
                </div>
              )}
            </div>
          </Card>
        </div>

        {/* Debit from Customers */}
        <div className="col-md-4">
          <Card className="h-100">
            <CardInfoDollar
              className="d-flex justify-content-center align-items-center"
              isLoading={isLoading.card}
              title="Débitos de Usuários"
              subtitle="Remoção de Saldo pelo Admin"
              value={Number(financialData?.userBalanceRemoval?.amount ?? 0)}
            />
          </Card>
        </div>

        {/* Profit Result */}

      </div>

      {/* All Platform Deficits */}
      <div className="row gap-3 gap-md-0 my-3">
        <div className="col-md-4">
          <Card className="h-100">
            <CardInfoDollar
              className="d-flex justify-content-center align-items-center"
              isLoading={isLoading.card}
              title="Recursos Totais"
              subtitle="Total de recursos captados"
              value={Number(financialData?.totalResources?.amount ?? 0)}
            />
          </Card>
        </div>
        {/* Provider Fees */}
        {/* <div className="col-md-3">
          <Card className="h-100">
            <div className="card-header">
              <div className="card-title d-flex justify-content-between">
                <div className="">Provedor Interno</div>
                <div className="fs-5"><FaInfoCircle title="Custas de operação da Plataforma." /></div>
              </div>
              <div className="card-category p-0 m-0">
                <small>Custas de Operação</small>
              </div>
            </div>
            <div className="card-body d-flex justify-content-center align-items-center">
              {isLoading.card ? (
                <div className="w-100">
                  <LoadingSkeleton count={2} />
                </div>
              ) : (
                <h4 className="fw-bolder">
                  {masks.dollarCurrency(Number(financialData?.billsBalance ?? 0))}
                </h4>
              )}
            </div>
          </Card>
        </div> */}

        {/* External Fees */}
        {/* <div className="col-md-3">
          <Card className="h-100">
            <CardInfoDollar
              className="d-flex justify-content-center align-items-center"
              isLoading={isLoading.card}
              title="Custo da Taxa de Saque"
              subtitle="Gateways e Outros"
              value={Number(financialData?.refactorFees?.totalGateway ?? 0)}
            />
          </Card>
        </div> */}

        {/* Deficit Result */}
        <div className="col-md-4">
          <Card className="h-100">
            <CardInfoDollar
              className="d-flex justify-content-center align-items-center"
              isLoading={isLoading.card}
              title="Custos Totais"
              subtitle="Total de despesas"
              value={Number(financialData?.totalCosts?.amount ?? 0)}
            />
          </Card>
        </div>

        {/* Credit for Customers */}
        <div className="col-md-4">
          <Card className="h-100">
            <CardInfoDollar
              className="d-flex justify-content-center align-items-center"
              isLoading={isLoading.card}
              title="Crédito p/ Usuários"
              subtitle="Adição de Saldo pelo Admin."
              value={Number(financialData?.userBalanceAdding?.amount ?? 0)}
            />
          </Card>
        </div>
      </div>

      {/* Transactions Table */}
      <div className="row">
        <div className="col-md-12">
          <Card>
            <div className="card-header">
              <div className="row">
                <div className="col-md-10">
                  <h4 className="card-title">Transações</h4>
                  <p className="card-category">Lista de todas as transações</p>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <div id="basic-datatables_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                  <div id="basic-datatables_length" className="dataTables_length">
                    <table className="table table-striped">
                      <thead>
                        <tr role="row">
                          <th scope="col" className="fs-6">#</th>
                          <th>Usuário</th>
                          <th>Origem</th>
                          <th>Destino</th>
                          <th>Currency</th>
                          <th>Tokens</th>
                          <th>Hash</th>
                          <th>Gas</th>
                          <th>Criado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading.table && <tr>
                          <td colSpan={10} className="text-center">
                            <LoadingSkeleton />
                          </td>
                        </tr>}

                        {transactions && transactions.length > 0 && (
                          transactions.map((transaction) => (
                            <tr key={transaction?.id}>
                              <td>{transaction?.id}</td>
                              <td><a href={`/user/info/${transaction?.customerId}`} className="btn btn-sm">{transaction?.customer}</a></td>
                              <td><a href={`https://polygonscan.com/address/${transaction.address_from}`} target="_blank" rel="noreferrer">{formatAddress(transaction.address_from)}</a></td>
                              <td><a href={`https://polygonscan.com/address/${transaction.address_to}`} target="_blank" rel="noreferrer">{formatAddress(transaction.address_to)}</a></td>
                              <td>{masks?.cryptoCurrency(transaction?.amount || 0)}</td>
                              <td>{transaction?.currency || "~"}</td>
                              <td>
                              <a href={`https://polygonscan.com/tx/${transaction.transactionHash}`} target="_blank" rel="noreferrer">{formatAddress(transaction.transactionHash)}</a></td>
                              <td>{transaction?.transactionFee || "~"}</td>
                              <td>{transaction?.createdAt ? dayjs(transaction?.createdAt).format('DD/MM/YYYY [ás] HH:MM:ss') : "~"}</td>
                            </tr>
                          ))
                        )}

                        {isLoading.table || !transactions || transactions?.length <= 0 && <tr>
                          <td colSpan={10} className="text-center">Nenhum registro encontrado.</td>
                        </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <Pagination page={page} setPage={setPage} totalPages={totalPages} maxPageShow={3} />
            </div>
          </Card>
        </div>
      </div>
    </>
  )
}