import { ConfirmButton } from "@components/buttons/confirm-button";
import { Card } from "@components/cards/card";
import { InputCurrency } from "@components/forms/input-currency";
import { IRouletteDTO } from "@hooks/dtos/IRouletteDTO";
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";
import { api } from "@service/api";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

interface IForm {
    value: number;
    method: number;
    otp?: string;
    pin?: string;
}

export function Roulette() {
    const { actions: { pushToastify } } = ZuToastifyStore();
    const [rouletteInfo, setRouletteInfo] = useState<IRouletteDTO[]>([]);
    const { register, setValue, handleSubmit, watch, reset, formState: { errors } } = useForm<IForm>();

    async function GetPriceAndActive() {
        try {
            const response = await api.get("/manager/roulette/get/price");
            setRouletteInfo(response.data);

            const activeItem = response.data.find((item: IRouletteDTO) => item.status === 'a');

            if (activeItem) {
                reset({
                    value: activeItem.value,
                    method: activeItem.id,
                });
            }
        } catch (error: any) {
            pushToastify({
                message: error?.response?.data.message ?? "Ocorreu um erro ao buscar as informações!",
                type: 'error',
                theme: "dark",
            });
        }
    }

    async function UpdatePrice(data: IForm) {
        try {
            await api.post("/manager/customization/roulette_prices", {
                value: data.value,
                status: "a",
                priceId: data.method,
                otpAuth: data.otp,
                pin: data.pin,
            });
            pushToastify({
                message: 'Atualização concluída com sucesso',
                type: 'success',
                theme: 'dark'
            });
        } catch (error: any) {
            pushToastify({
                message: error?.response?.data.message ?? "Ocorreu um erro ao atualizar as informações!",
                type: 'error',
                theme: 'dark'
            });
        }
    }

    useEffect(() => {
        if (rouletteInfo.length === 0) {
            GetPriceAndActive();
        }
    }, [rouletteInfo]);

    const value = watch('value');
    const method = watch('method');

    return (
        <>
            <Card className="card-round">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-10">
                            <h4 className="card-title">Roleta</h4>
                            <p className="card-category">Alterar informações de cobrança do giro diário</p>
                        </div>
                    </div>
                </div>
                <div className="card-body mt-3">
                    <form onSubmit={handleSubmit(UpdatePrice)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-floating mb-3">
                                    <select
                                        className={`form-select ${errors.method ? 'is-invalid' : ''}`}
                                        {...register('method')}
                                    >
                                        {rouletteInfo.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                    <label>Método</label>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <InputCurrency
                                    prefix="R$"
                                    value={value}
                                    onChangeTextOut={(val: number) => setValue('value', val)}
                                    animated
                                    label="Valor"
                                />
                            </div>
                        </div>

                        <div className="text-end mt-3">
                            <ConfirmButton
                                validations="doubleCheck"
                                textConfirmation="Por favor, confirme sua Senha, OTP e o envio do Formulário para a alteração ser feita!"
                                needConfirm={true}
                                onConfirm={(e, data) => {
                                    if (e == true) {
                                        setValue("otp", data?.otp);
                                        setValue("pin", data?.pin);
                                        handleSubmit(UpdatePrice)();
                                    }
                                }}
                            />
                        </div>
                    </form>
                </div>
            </Card>
        </>
    );
}
